import { Pipe, PipeTransform } from '@angular/core';
import { Chat } from '../../models/chat/chat';
import { UsuarioChat } from '../../models/chat/usuario-chat';

@Pipe({
  name: 'usuarioChat',
  pure: false
})
export class UsuarioChatPipe implements PipeTransform {

  transform(chat: Chat, usuarios: UsuarioChat[]): string {
    const usuario: UsuarioChat | undefined = usuarios.find( us => us.id == chat.remitente_id || us.id == chat.destinatario_id );
    return usuario ? usuario.obtenerNombreYApellido() : '...';
  }

}
