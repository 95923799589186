import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { PipeModule } from '../pipes/pipe.module';
import { BannerTitleComponent } from './banner-title/banner-title.component';
import { FalsoPopUpComponent } from './falso-pop-up/falso-pop-up.component';
import { SidebarComponent } from './sidebar/sidebar.component';



@NgModule({
  declarations: [
    FalsoPopUpComponent,
    SidebarComponent,
    BannerTitleComponent,
  ],
  exports: [
    FalsoPopUpComponent,
    SidebarComponent,
    BannerTitleComponent,
  ],
  imports: [
    CommonModule,
    TranslateModule,
    PipeModule,
    AngularSvgIconModule
  ]
})
export class ComponentsModule { }
