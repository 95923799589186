import { HttpClient } from '@angular/common/http';
import { Injectable, isDevMode } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { ApiUsuario } from '../models/api/api-usuario';
import { BolsaMultimedia } from '../models/evento/multimedia/multimedia';
import { Sesion } from '../models/evento/sesion/sesion';
import { Usuario } from '../models/usuario/usuario';
import { ApiService } from './api.service';

const BASE_URL = environment.api + '/usuario';

@Injectable({
  providedIn: 'root'
})
export class UsuarioService {

  private _listado ?: Usuario[];
  private _subject$ = new BehaviorSubject<Usuario[]>([]);

  constructor(private api: ApiService, private http: HttpClient) {}

  init() {
    this.cargarUsuariosEvento();
  }

  get usuarios$(): Observable<Usuario[]> {
    return this._subject$.asObservable();
  }

  get listado(): Usuario[] {
    return this._listado || [];
  }

  private cargarUsuariosEvento(pagina: number = 1, cantidad: number = 1_000_000) {
    return this.http.get( BASE_URL + `s/${ pagina }/${ cantidad }` ).pipe(
      map( (respuesta: any) => respuesta.usuarios_evento),
      map( (usuarios: Usuario[]) => usuarios.filter( (usuario: Usuario) => usuario.networking ) ),
      map( (usuarios: Usuario[]) => usuarios.sort( (u1, u2) => u1.usuario.nombre.localeCompare(u2.usuario.nombre) ) ),
      tap( (usuarios: Usuario[]) => this._subject$.next(usuarios) )
    ).subscribe(
      (usuarios: Usuario[]) => this._listado = usuarios
    );
  }

  actualizarCurriculum(curriculum: string): Observable<Usuario> {
    return this.http.post<ApiUsuario>(BASE_URL, {
      curriculum: curriculum,
      centro_trabajo: this.api.usuario.centro_trabajo,
      cargo: this.api.usuario.cargo,
      telefono: this.api.usuario.telefono
    }).pipe(
        map( (respuesta: ApiUsuario) => respuesta.usuario_evento ),
        tap( (usuario: Usuario) => this.api.usuario.curriculum = usuario.curriculum )
    );
  }

  cambiarAvatar(file: File): Observable<Usuario> {
    const formData = new FormData();
    formData.append('imagen', file);

    return this.http.post<ApiUsuario>(BASE_URL + '/imagen', formData).pipe(
      map( (respuesta: ApiUsuario) => respuesta.usuario_evento ),
      tap( (usuario: Usuario) => this.api.usuario.avatar = usuario.avatar )
    );
  }

  cambiarEstadoNetworking(activar: boolean): Observable<Usuario> {
    return this.http.post<ApiUsuario>(BASE_URL, {
      curriculum: this.api.usuario.curriculum,
      centro_trabajo: this.api.usuario.centro_trabajo,
      cargo: this.api.usuario.cargo,
      telefono: this.api.usuario.telefono,
      networking: activar ? 1 : 0
    }).pipe(
        map( (respuesta: ApiUsuario) => respuesta.usuario_evento ),
        tap( (usuario: Usuario) => this.api.usuario.networking = usuario.networking )
    );
  }

  eliminarAvatar(): Observable<any> {
    return this.http.post<ApiUsuario>(BASE_URL + '/imagen/eliminar', {}).pipe(
      map( (respuesta: ApiUsuario) => respuesta.usuario_evento ),
      tap( (usuario: Usuario) => this.api.usuario = usuario )
    );
  }

  /**
   * Gestiona las sesiones favoritas, ya sea para insertarlas o para eliminarlas
   * @param idSesion Identificador de la sesión
   * @param eliminar Por defecto es false
   */
  gestionarSesionFavorita(idSesion: number, eliminar: boolean = false): Observable<Sesion[]> {
    let url = BASE_URL + '/sesiones_favoritas/' + idSesion;
    if ( eliminar ) url = url + '/eliminar';

    return this.http.post(url, {}).pipe(
        map( (respuesta: any) => respuesta.sesiones_favoritas ),
        tap( (favoritas: Sesion[]) => this.api.usuario.sesiones_favoritas = favoritas )
    );
  }


  gestionarBolsaMultimedia(idEmpresa: number, idMedia: number, eliminar: boolean = false): Observable<BolsaMultimedia[]> {
    let url = BASE_URL + `/bolsa_multimedia/${ idEmpresa }/${ idMedia }`;
    if ( eliminar ) url = url + '/eliminar';

    return this.http.post(url, {}).pipe(
        map( (respuesta: any) => {
          this.api.usuario.bolsa_multimedia = respuesta.bolsa_multimedia;
          return respuesta.bolsa_multimedia;
        })
    );
  }
}
