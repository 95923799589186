import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Language } from 'src/app/models/api/lang.type';
import { Evento, IEventoDTO } from 'src/app/models/evento/evento';

@Pipe({
  name: 'fechas'
})
export class FechasPipe implements PipeTransform {

  lang: Language;

  constructor(
    private datePipe: DatePipe,
    private translateService: TranslateService
  ) { 
    this.lang = <Language> this.translateService.currentLang;
  }

  transform(evento: IEventoDTO | Evento): string {
    const fechaInicio: string = evento.fecha_inicio;
    const fechaFin: string = evento.fecha_fin;

    let formatoInicio = '';

    // Si el evento se desarrolla en dos años se muestra el rango con dos fechas completas ( 3 diciembre 2020 - 3 enero 2021 )
    if ( new Date(fechaInicio).getFullYear() != new Date(fechaFin).getFullYear() ) formatoInicio = 'd MMMM yyyy';
    // Si el evento se desarrolla en diferentes meses muestra el día y los meses ( 1 marzo - 3 abril 2021 )
    else if ( new Date(fechaInicio).getMonth() != new Date(fechaFin).getMonth() ) formatoInicio = formatoInicio + 'd MMMM';
    // Si el evento se desarrolla en el mismo mes se muestra con el formato 1 - 4 marzo 2021
    else if ( fechaInicio != fechaFin ) formatoInicio = 'd';
    // Si el evento dura un día sólo se muestra una fecha

    const offset = this._getTimezoneOffset('Europe/Madrid');
    const timezone = `+0${ offset }00`;
    const inicio = this.datePipe.transform(fechaInicio, formatoInicio, timezone, this.lang);
    const fin = this.datePipe.transform(fechaFin, 'd MMMM yyyy', timezone, this.lang);

    return inicio + ' - ' + fin;
  }

  private _getTimezoneOffset(timeZone: string) {
    const now = new Date();
    const tzString = now.toLocaleString('en-US', { timeZone });
    const localString = now.toLocaleString('en-US');
    const diff = (Date.parse(localString) - Date.parse(tzString)) / 3600000;
    const offset = diff + now.getTimezoneOffset() / 60;
    
    return -offset;
  }

}
