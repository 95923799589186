import { Injectable, isDevMode } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RegistroService {

  private readonly URL: string = environment.api + '/registro'

  constructor(
    private http: HttpClient
  ) {
    if ( isDevMode() ) console.log('-- RegistroService --')
  }

  registrarUsuario(data: any): Observable<unknown> {
    return this.http.post(this.URL, data);
  }

  enviarCorreoVerificacion(email: string): Observable<unknown> {
    return this.http.post(this.URL + '/enviar-verificacion', { email: email });
  }

}
