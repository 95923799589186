import { Injectable, isDevMode } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';
import { PermisoService } from '../services/permiso.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(
    private auth: AuthService,
    private permiso: PermisoService,
    private router: Router
  ) {}

  canActivate(
    _: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if ( !this.auth.token ) {
      localStorage.setItem('url-to-navigate', state.url);
      this.router.navigate(['/login']);

      return false;
    }

    return this.auth.comprobarValidezToken().pipe(
        map( () => this.puedeAccederPorFecha() ),
        catchError( () => {
          localStorage.setItem('url-to-navigate', state.url);
          return of( false );
        })
    );
  }

  private puedeAccederPorFecha(): boolean {
    const puedeAcceder: boolean = this.permiso.puedeAccederPorFecha;

    if ( !puedeAcceder ) {
      alert('Tu plan ya no te permite acceder a la plataforma.');
      this.auth.cerrarSesion();
    }

    return puedeAcceder;
  }
  
}
