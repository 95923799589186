import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'keys',
  // pure: false
})
export class KeysPipe implements PipeTransform {

  transform(object: { [key: string]: any }): string[] {
    return Object.keys(object);
  }

}
