import { registerLocaleData } from '@angular/common';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import locale from '@angular/common/locales/en';
import localeEs from '@angular/common/locales/es';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BienvenidaComponent } from './full-pages/bienvenida/bienvenida.component';
import { BienvenidaDirective } from './full-pages/bienvenida/bienvenida.directive';
import { LoginComponent } from './full-pages/login/login.component';
import { LoginDirective } from './full-pages/login/login.directive';
import { RecuperarPasswordComponent } from './full-pages/recuperar-password/recuperar-password.component';
import { ApiService } from './services/api.service';
import { ChatService } from './services/chat.service';
import { DomService } from './services/dom.service';
import { FancyboxService } from './services/fancybox.service';
import { Interceptor } from './services/interceptor';
import { SocketService } from './services/socket.service';
import { SharedModule } from './shared/shared.module';
import Swal from 'sweetalert2';

const createTranslateLoader = (http: HttpClient) => new TranslateHttpLoader(http, './assets/i18n/', '.json?v=' + Date.now());

const inicializarServicios = (api: ApiService, socket: SocketService, translate: TranslateService, ...args: any) => {

  return async () => {
    try {
      await api.init();
      await socket.init();
  
      const lang = api.evento.idioma;
      translate.setDefaultLang(lang);
      translate.use(lang);
  
      api.cargarTraducciones();
  
      localStorage.setItem('loader-lang', lang);
  
      registerLocaleData(lang === 'es' ? localeEs : locale, lang);
  
      await Promise.all(args.map( (elem: any) => elem.init() ));
    } catch (error) {
      document.body.classList.remove('loading');

      Swal.fire({
        title: 'En mantenimiento',
        text: 'Estamos realizando tareas de mantenimiento, por favor inténtelo de nuevo más tarde.',
        icon: 'info',
        confirmButtonText: 'Aceptar',
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
      }).then(() => location.reload());
    }
  }
}

@NgModule({
  declarations: [
    AppComponent,
    BienvenidaComponent,
    LoginComponent,
    RecuperarPasswordComponent,
    LoginDirective,
    BienvenidaDirective
  ],
  imports: [
    AppRoutingModule,
    RouterModule,
    BrowserModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    }),
    ReactiveFormsModule,
    BrowserAnimationsModule,
    SharedModule,
    FormsModule,
    AngularSvgIconModule.forRoot()
  ],
  exports: [TranslateModule],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: inicializarServicios,
      deps: [
        ApiService,
        SocketService,
        TranslateService,
        DomService,
        FancyboxService,
        ChatService
      ],
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: Interceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
