<nav class="navbar navbar-expand-lg navbar-dark bg-dark">
    <div class="container-fluid">

        <a class="navbar-brand d-none d-sm-block d-xl-none" routerLink="/evento">
            <div [innerHTML]="'navbar.onsite.titulo1' | translate"></div>
        </a>

        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav me-auto mb-2 mb-lg-0">

                <ng-container *ngFor="let item of menu" class="nav-item" [ngSwitch]="item.tipo">

                    <li *ngSwitchCase="'routerLink'">
                        <a class="nav-link active" aria-current="page" [routerLink]="item.data" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">
                            {{ item.titulo | translate }}
                        </a>
                    </li>

                    
                    <li *ngSwitchCase="'popUp'" class="nav-item dropdown">
                        <a class="nav-link dropdown-toggle active" id="navbarAyuda" role="button" (click)="abrirPopUp(item.data)"  data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">
                            {{ item.titulo | translate }}
                        </a>
                    </li>

                    <li *ngSwitchCase="'dropdown'" class="nav-item dropdown">
                        <a class="nav-link dropdown-toggle active" href="#" id="navbarPrograma" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                            {{ item.titulo | translate }}
                        </a>
                        <ul class="dropdown-menu" aria-labelledby="navbarPrograma">
                            <li *ngFor="let subitem of item.data" class="nav-item" [ngSwitch]="subitem.tipo">
                                <a *ngSwitchCase="'routerLink'" class="dropdown-item" [routerLink]="subitem.data" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">
                                    {{ subitem.titulo | translate }}
                                </a>

                                <a *ngSwitchCase="'popUp'" role="button" class="dropdown-item" (click)="abrirPopUp(subitem.data)" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">
                                    {{ subitem.titulo | translate }}
                                </a>

                                <a *ngSwitchCase="'link'" (click)="visitarEnlace(subitem.titulo, subitem.data)" role="button"target="_blank" class="dropdown-item" [href]="subitem.data">
                                    {{ subitem.titulo | translate }}
                                </a>
                            </li>
                            
                        </ul>
                    </li>

                </ng-container>
                
                   
                
                <li *ngIf="permiso.esAdmin" class="nav-item dropdown">
                  <a class="nav-link dropdown-toggle active" id="navbarPrograma" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                      {{ 'navbar.gestion.titulo'| translate }}
                  </a>
                  <ul class="dropdown-menu" aria-labelledby="navbarPrograma">

                      <li class="nav-item">
                          <a class="dropdown-item" routerLink="/evento/modera" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">
                              {{ 'navbar.gestion.modera' | translate }}
                          </a>
                      </li>

                      <li class="nav-item">
                          <button class="dropdown-item" data-fancybox="broadcast" data-src="#difusion-pop-up" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">
                              {{ 'navbar.gestion.difusion' | translate }}
                          </button>
                      </li>
                      
                  </ul>
               </li>
               <li *ngIf="idiomas.length > 1" class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle active" id="navbarPrograma" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                        <img class = 'flag' src="assets/imgs/flags/{{ translateService.currentLang }}.png" [alt]="translateService.currentLang">
                        {{ 'navbar.idioma.' + translateService.currentLang | translate }}
            
                        <!--{{ 'navbar.idioma.titulo' | translate }}-->
                    </a>
            
                    <ul class="dropdown-menu" aria-labelledby="navbarPrograma">
                        <li class="nav-item" *ngFor="let lang of idiomas">
                            <ng-container *ngIf ="lang != translateService.currentLang">
                                <button class="dropdown-item" (click)="api.cambiarIdioma(lang)" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">
                                    <img class="flag" src="assets/imgs/flags/{{ lang }}.png" [alt]="lang">  
                                    {{ 'navbar.idioma.' + lang | translate }}
                                </button>
                            </ng-container>
                            
                        </li>
                    </ul>
                </li>

                <li class="nav-item">
                    <a class="nav-link active" aria-current="page" role="button" (click)="auth.cerrarSesion()">
                        {{ 'navbar.cerrar_sesion' | translate }}
                    </a>
                </li>

            </ul>
        </div>

        <a class="navbar-brand d-none d-xl-block" routerLink="/evento">
            <div [innerHTML]="'navbar.onsite.titulo2' | translate"></div>
        </a>
    </div>
</nav>
