import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NetworkingChatComponent } from './networking-chat.component';
import { PipeModule } from '../../../pipes/pipe.module';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { DragDropChatDirective } from './drag-drop-chat.directive';
import { AngularSvgIconModule } from 'angular-svg-icon';

@NgModule( {
  declarations: [
    NetworkingChatComponent,
    DragDropChatDirective
  ],
  exports: [
    NetworkingChatComponent
  ],
  imports: [
    CommonModule,
    PipeModule,
    FormsModule,
    AngularSvgIconModule,
    TranslateModule
  ]
})
export class NetworkingChatModule { }
