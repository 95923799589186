<section *ngIf="imagen || video" class="d-inline-block">
  <div *ngIf="imagen && !video" (click)="registrarVisita()">
    <img *ngIf="!imagen.includes('.mp4')" [src]="imagen" width="200" height="150" />

    <video *ngIf="imagen?.includes('.mp4')" 
           class="pointer" 
           [muted]="true"
           width="320" height="240" autoplay="autoplay"
           [attr.data-fancybox]="nombreRecurso"
           [attr.data-src]="imagen">
      <source [src]="imagen" type="video/mp4">
    </video>
  </div>

  <div *ngIf="video" (click)="registrarVisita()" class="clickable" [attr.data-fancybox]="nombreRecurso" [attr.data-src]="video | iframe : 'data-src'">
    <img *ngIf="imagen" [src]="imagen" width="200" height="150" />
  </div>

  <iframe
    *ngIf="!imagen && video"
    frameborder="0"
    height="360"
    width="640"
    allow="autoplay; fullscreen"
    [src]="video | iframe : 'iframe'">
  </iframe>
</section>
