import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[appBienvenida]'
})
export class BienvenidaDirective {

  @HostListener('click', ['$event.target'])
  onClick() {
    document.body.classList.add('loading');
 }

  constructor( ) {
    
  }

  ngOnDestroy(): void {
    document.querySelector('.bienvenida')?.remove();
    document.body.classList.remove('loading');
  }

}
