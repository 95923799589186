import { Injectable, isDevMode } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { OneToOneService } from './one-to-one.service';

@Injectable({ providedIn: 'root' })
export class ComunicacionInternaService {

  constructor( private oneToOneService: OneToOneService ) { }

  // AYUDA
  private ayuda$ = new Subject<boolean>();

  mostrarAyuda(mostrar: boolean): void {
    if ( isDevMode() ) console.log(mostrar ? 'Abriendo ayuda' : 'Cerrando ayuda');
    this.ayuda$.next(mostrar);
  }

  obtenerAyuda(): Observable<boolean> {
    return this.ayuda$.asObservable();
  }

  // MENÚ USUARIO
  private menuAbierto: boolean = false;
  private menuUsuario$ = new Subject<void>();
  private miCalendario$ = new Subject<void>();
  private abrirCalendario$ = new BehaviorSubject<boolean>(false);

  alternarMenuUsuario(): void {
    if ( isDevMode() ) console.log('Alternando menú de usuario');
    this.menuAbierto = !this.menuAbierto;
    this.menuUsuario$.next();

    if ( !this.menuAbierto ) this.abrirCalendario$.next(false);
  }

  gestionarMenuUsuario(): Observable<void> {
    return this.menuUsuario$.asObservable();
  }

  abrirMiCalendario(): void {
    this.abrirCalendario$.next(true);
    this.miCalendario$.next();
  }

  gestionarMiCalendario(): Observable<void> {
    return this.miCalendario$.asObservable();
  }

  obtenerMiCalendario(): Observable<boolean> {
    return this.abrirCalendario$.asObservable();
  }

  // POP UP GENERAL
  abrirPopUp(popUp: 'hospitality' | 'usuario' | 'ayuda', mostrar: boolean = true) {
    switch (popUp) {

      case 'ayuda': {
        this.mostrarAyuda(mostrar);
        break;
      }

      case 'hospitality': {
        this.oneToOneService.abrirHospitality();
        break;
      }

      case 'usuario': {
        this.alternarMenuUsuario();
        break;
      }
      
    }
  }
  
}
