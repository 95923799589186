import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { IPonenteConfig } from '../models/evento/ponente/ponente';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  constructor(private readonly api: ApiService) {}

  get estilos(): any {
    try {
      return JSON.parse( this.api.evento.frontend_colores ).premium;
    } catch (error) {
      return {};
    }
  }

  get ponentes(): IPonenteConfig {
    const config = this.estilos.ponentes || {};

    // Revisar que el valor es estrictamente false, si no está definido significa que
    // no está configurado por lo que se asume que es true

    if ( config?.titulo !== false )
      config.titulo = true;

    if ( config?.cargo !== false )
      config.cargo = true;

    if ( config?.centro_trabajo !== false )
      config.centro_trabajo = true;

    return config;
  }
}
