import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, isDevMode } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import { Evento } from 'src/app/models/evento/evento';
import { tokens } from 'src/tokens/tokens';
import { environment } from '../../environments/environment';
import { Language } from '../models/api/lang.type';
import { Empresa } from '../models/evento/empresa/empresa';
import { Enlace } from '../models/evento/enlace/enlace';
import { ApiEvento } from '../models/evento/evento';
import { Multimedia } from '../models/evento/multimedia/multimedia';
import { Sala } from '../models/evento/sala/sala';
import { Usuario } from '../models/usuario/usuario';

const URL: string = environment.api;
declare const gtag: any;

interface Global {
    token: string;
}


@Injectable({
    providedIn: 'root'
})
export class ApiService {

    usuario !: Usuario;
    evento  !: Evento;

    private global!: Global;

    readonly routeChages$ = new BehaviorSubject<NavigationEnd>({
      id: 0,
      url: '',
      urlAfterRedirects: ''
    });

    constructor(
        private http: HttpClient,
        private router: Router,
        private translate: TranslateService
    ) {
    }
    
    async init() {
      this.global = await this.inicializarGlobal();
      this.evento = await this.cargarEvento().toPromise();
      this.insertarScriptsPersonalizados();

      if (this.evento.analytics)
        this._addGoogleAnalytics(this.evento.analytics);
      
      return this.evento;
    }

    private getEventToken(): Promise<string> {
      return this.http.get(URL + '/evento/token').pipe(
        map( (response: any) => response.token ),
      ).toPromise().catch( () => null );
    }

    private async inicializarGlobal(): Promise<Global> {

        this.comprobarVersion();

        const hostname = location.hostname.startsWith('192.168') ? 'localhost' : location.hostname;
        const token: string = await this.getEventToken() || tokens[hostname];

        if ( !token ) alert('No se ha encontrado el evento');

        return { token };
    }

    private comprobarVersion() {

      const myVersion = 'v0.3.5';

      setInterval( () => {

        this.http.get('assets/version.json', {
          headers: {
            'Cache-Control': 'no-cache',
            'Pragma': 'no-cache',
            'Expires': 'Sat, 01 Jan 2000 00:00:00 GMT'
          }, 
        }).pipe(
          filter( ({ version }: any) => version !== myVersion )
        ).subscribe( 
          () => setTimeout( () => location.reload(), 1000 * 50 )
        );

      }, 1000 * 60); // Cada minuto

    }

    // Getters / Setters

    get token(): string {
      return this.global?.token || '';
    }

    get organizadores(): Empresa[] {
      return this.evento.empresas.filter( 
        empresa => empresa.tipo_empresa?.nombre === 'Organizadores' 
      );
    }

    get secretaria(): Empresa | undefined {
      return this.evento.empresas.find( empresa => empresa.tipo_empresa?.nombre === 'Secretaría' );
    }

    // Evento
    cargarEvento(): Observable<Evento> {
      return this.http.get<ApiEvento>(URL + '/evento').pipe(
        map( (respuesta: ApiEvento) => new Evento(respuesta.evento) )
      );
    }

    actualizarSesiones(idSesion: number, empieza: boolean): void {
        const indice = this.evento.sesiones.findIndex( sesion => sesion.id == idSesion );

        if ( indice > -1 ) {

            if ( empieza ) this.evento.sesiones[ indice ].inicio_real = new Date().toISOString();
            else this.evento.sesiones[ indice ].fin_real = new Date().toISOString();
            
        }
    }

    // Multimedia
    obtenerEnlaceMultimedia(
        nombre: string,
        config?: {
            empresa ?: Empresa,
            muted   ?: boolean
        }
    ): string | undefined {
        const enlaces = config?.empresa?.enlaces || this.evento.enlaces;
        const enlace: Enlace | undefined = enlaces.find( e => e.titulo === nombre );
        let link: string | undefined = enlace?.enlace;

        if ( link ) {
          if ( link.includes('vimeo') ) {
            return link;
          } else if ( link.includes('youtube') ) {
            return this.obtenerLinkYoutube( link, config?.muted );
          } else {
            link += '?autoplay=1&autopause=0';
            if ( config?.muted ) link += '&background=1&quality=240p';
          }
        }

        return link;
    }

    private obtenerLinkYoutube(link: string, muted?: boolean): string {
      return muted ? link + '?autoplay=1&controls=0&mute=1&loop=1' : link + '?rel=0';
    }

    obtenerMultimedia(nombre: string, empresa?: Empresa): string | undefined {
        const elementosMultimedia = empresa ? empresa.multimedia : this.evento.multimedia;
        const multimedia: Multimedia | undefined = elementosMultimedia.find( m => m.nombre == nombre );
        return multimedia?.url;
    }

    // Obtiene las salas que irán a la sala principal
    obtenerSalasDeLaSalaPrincipal(): Sala[] {
        return this.evento.salas.filter( sala => sala.orden > 0 );
    }

    /* Documentos privados */
    obtenerDocumentosPatrocinadores(): Observable<any> {
        return this.http.get(URL + '/evento/empresas').pipe(
            map( (res: any) => this.evento.empresas = res.empresas )
        );
    }

    /**
     * Inserta código implementado desde el panel de control. Este código se ejecuta inmediatamente 
     * y después de cada cambio de url.
     */
    private insertarScriptsPersonalizados() {
      const ejecutar = () => {
        
        try {
          const code = JSON.parse( this.evento.frontend_colores ).premium.scripts;
          eval(code);
        } catch (err) {
          if ( isDevMode() ) console.error(err);
        }

      };
  
      this.router.events.pipe(
        filter( event => event instanceof NavigationEnd ),
        map( event => <NavigationEnd> event ),
        tap((data: NavigationEnd) => this.routeChages$.next(data))
      ).subscribe( ejecutar );
  
    }

    cambiarIdioma(lang: Language) {
      if ( lang == this.evento.idioma ) return;
      
      localStorage.setItem('loader-lang', lang);
      localStorage.setItem('lang', lang);
      location.reload();
    }



    cargarTraducciones() {
    
      // Evento
      this.evento = this.traducirCampos(this.evento);
  
      // Áreas temáticas
      this.evento.areas_tematicas = <any> this.evento.areas_tematicas.map( area => this.traducirCampos(area) );
  
      // Tipos de sesión
      this.evento.tipos_sesion = <any> this.evento.tipos_sesion.map( tipos => this.traducirCampos(tipos) );
  
      // Salas
      this.evento.salas = <any> this.evento.salas.map( sala => {
        sala['audios'] = this.cargarAudios(sala);
        sala = this.traducirCampos(sala);
  
        sala['html_extra'] = sala['htmls_extra'].map( extra => this.traducirCampos(extra) ).find( ({ nombre }) => nombre === 'html_extra' )?.html;
        sala['html_extra_alt'] = sala['htmls_extra'].map( extra => this.traducirCampos(extra) ).find( ({ nombre }) => nombre === 'html_extra_alt' )?.html;
  
        return sala;
      });
      
      // Sesiones
      this.evento.sesiones = <any> this.evento.sesiones.map( sesion => {
        sesion = this.traducirCampos(sesion);

        if ( sesion.tipo_sesion )
          sesion.tipo_sesion = this.traducirCampos(sesion.tipo_sesion);
        
        sesion['audios'] = this.cargarAudios(sesion);
  
        sesion.detalles = <any> sesion.detalles.map( detalle => {
          detalle = this.traducirCampos(detalle);
          detalle['audios'] = this.cargarAudios(detalle);
  
          detalle.html_extra = detalle['htmls_extra'].map( extra => this.traducirCampos(extra) ).find( ({ nombre }) => nombre === 'html_extra' )?.html;
          detalle['html_extra_alt'] = detalle['htmls_extra'].map( extra => this.traducirCampos(extra) ).find( ({ nombre }) => nombre === 'html_extra_alt' )?.html;
              
          detalle.autores = <any> detalle.autores.map( autor => this.traducirCampos(autor) );
  
          return detalle;
        });
  
        sesion.moderadores = <any> sesion.moderadores.map( moderador => this.traducirCampos(moderador) );
        
        sesion.html_extra = sesion['htmls_extra'].map( extra => this.traducirCampos(extra) ).find( ({ nombre }) => nombre === 'html_extra' )?.html;
        sesion['html_extra_alt'] = sesion['htmls_extra'].map( extra => this.traducirCampos(extra) ).find( ({ nombre }) => nombre === 'html_extra_alt' )?.html;
        
        return sesion;
      });
  
      // Empresas
      this.evento.empresas = <any> this.evento.empresas.map( empresa => {
        if ( empresa.tipo_empresa ) 
          empresa.tipo_empresa = this.traducirCampos(empresa.tipo_empresa);
  
        return empresa;
      });
  
      // Contenidos
      this.evento.contenidos = <any> this.evento.contenidos.map( (contenido: any) => this.traducirCampos(contenido) );
  
  
      // Textos legales
      this.evento.textos_legales = <any> this.evento.textos_legales.map( texto => this.traducirCampos(texto) );
  
    }

    private traducirCampos(
      object: { [key: string]: any }
    ): any {
  
      let result = Object.assign({}, object);
  
      try {
        const traducciones = object.traducciones?.[this.translate.currentLang];
  
        Object.keys( traducciones ).filter(
          (key: string) => traducciones[key]
        ).forEach(
          (key: string) => result[key] = traducciones[key]
        );
  
      } catch (error) {
        result = object;
      }
  
      return result;
  
    }


  private cargarAudios(video: any) {
    const vo = video['htmls_extra'].find( ({ nombre }: any) => nombre === 'html_extra_original' )?.html;
    const es = video['htmls_extra'].find( ({ nombre }: any) => nombre === 'html_extra' )?.html;
    const en = video['htmls_extra'].find( ({ nombre }: any) => nombre === 'html_extra' )?.traducciones?.en?.html;

    const audios: any = { vo, es, en };
    Object.keys(audios).filter( (key) => !audios[key] ).forEach( key => delete audios[key] );

    return audios;
  }


  // Google analytics
  private _addGoogleAnalytics(gtagId: string) {
    const script = document.createElement('script');
    script.async = true;
    script.src   = `https://www.googletagmanager.com/gtag/js?id=${ gtagId }`;
    document.head.appendChild(script);
  
    const code = `
      window.dataLayer = window.dataLayer || [];
      function gtag() { dataLayer.push(arguments); }
      gtag('js', new Date());
    `;
  
    const script2 = document.createElement('script');
    script2.text  = code;
    document.head.appendChild(script2);
  
    this.routeChages$.subscribe( ({ urlAfterRedirects }) => {
      gtag('config', gtagId, {
        'page_path': urlAfterRedirects
      });
    });
  }


    // TIEMPOS
    enviarTiempos(sesionId: number | string, tiempos: { inicio ?: string, fin ?: string, detalle_sesion_id ?: number | string }) {
      const headers = new HttpHeaders({ Authorization: `Bearer ${ this.token }`});
      return this.http.post(URL + `/asistencia/${ sesionId }`, tiempos, { headers }).subscribe();
    }



}
