import { Component, isDevMode } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { AlertService } from 'src/app/services/alert.service';
import { ApiService } from 'src/app/services/api.service';
import { FondosService } from 'src/app/services/fondos.service';
import { AuthService } from "../../services/auth.service";

@Component({
  selector: 'app-recuperar-password',
  templateUrl: './recuperar-password.component.html',
  styleUrls: ['./recuperar-password.component.scss']
})
export class RecuperarPasswordComponent {

  readonly fondo: string;

  confirmarPass?: string;
  nuevaPass?: string;

  mostrarErrores: boolean = false;
  token?: string;

  cargando: boolean = false;

  constructor(
      private api: ApiService,
      private auth: AuthService,
      private alertService: AlertService,
      private router: Router,
      activatedRoute: ActivatedRoute,
      fondosService: FondosService
  ) {
    if ( isDevMode() ) console.log('-- RecuperarPasswordComponent --');

    this.fondo = fondosService.obtenerFondo('login');

    this.token = activatedRoute.snapshot.queryParams.token;
    auth.validarTokenRecuperacion( this.token ).subscribe(
        () => {},
        () => {
          router.navigate(['/login'], { replaceUrl: true }).then( () => {
            alertService.push({
              mensaje: 'El token de validación ha caducado, vuelva a iniciar el proceso de recuperación',
              tipo: 'info'
            });
          })
        }
    );
  }

  enviar(token: string): void {
    console.log(token,this.nuevaPass,this.confirmarPass);
    if ( !this.nuevaPass || this.nuevaPass != this.confirmarPass || this.nuevaPass.length < 6 ) {
      this.mostrarErrores = true;
      return;
    }

    this.cargando = true;

    this.auth.establecerPassword(this.nuevaPass, token).subscribe(
        _ => {
          this.router.navigate(['/login'], { replaceUrl: true }).then( _ => {
            this.alertService.push({
              mensaje: 'alerta.general.procesar.exito',
              tipo: 'success'
            });
          })
        }, () => {
          this.cargando = false;
          
          this.alertService.push({
            mensaje: 'alerta.general.procesar.exito',
            tipo: 'danger'
          })
        }
    )
  }
}
