import { Pipe, PipeTransform } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';

@Pipe({
  name: 'chatOrdenado',
  pure: false
})
export class ChatOrdenadoPipe implements PipeTransform {

  constructor( private api: ApiService ) {

  }
  
  transform(usuariosIds: number[], filtro: 'todos' | 'solo-chat' = 'todos'): number[] {
    return usuariosIds.length === 0 ? Object.keys(this.api.usuario.chats).map( id => +id ) : usuariosIds.filter(
      usuarioId => filtro === 'todos' || this.api.usuario.chats[usuarioId]
    ).sort( (id1, id2) => {

      const f1 = this.api.usuario.chats[ id1 ]?.created_at || '1999-01-01';
      const f2 = this.api.usuario.chats[ id2 ]?.created_at || '1999-01-01';
      
      return new Date(f1) > new Date(f2) ? -1 : 1;
    });
  }

}
