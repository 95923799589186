<section id="network" *ngIf="api.usuario.tipo_registro?.networking !== false && api.usuario.networking">

    <app-networking-chat *ngIf="chatService.abierto" (cerrar)="cerrarChat()" class="networking__chat"></app-networking-chat>

<!--    <div *ngIf="false" class="notificacionOne">-->
<!--        <h5>{{ 'networking.one_to_one.titulo' | translate }}</h5>-->
<!--        <p>{{ 'networking.one_to_one.resumen' | translate : { resumen: 'Fulanito', fecha: ' 12 marzo' } }}</p>-->
<!--        <p class="notifiacion__mensaje">Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quod consectetur error aliquid ducimus id hic sunt similique! Deserunt ullam necessitatibus soluta ipsum sint optio! Culpa corporis eaque placeat repudiandae aspernatur.</p>-->
<!--        <div class="notificacion__botonera">-->
<!--            <button>{{ 'networking.one_to_one.aceptar' | translate }}</button>-->
<!--            <button>{{ 'networking.one_to_one.rechazar' | translate }}</button>-->
<!--            <button>{{ 'networking.chat' | translate }}</button>-->
<!--            <button>{{ 'networking.one_to_one.ver_perfil' | translate }}</button>-->
<!--        </div>-->
<!--    </div>-->

    <div *ngIf="!chatService.abierto" class="network__min">
        <div (click)="desplegarContactos()" class="network__head" [class.pendiente]="chatService.pendientes > 0">
            <h6>
                {{ 'networking.chat' | translate }}
                <span *ngIf="chatService.pendientes > 0">({{ chatService.pendientes }})</span>
            </h6>
            <img src="assets/imgs/usuario/flecha-{{ flechaChat }}.svg" alt="networking">
        </div>
        <ul *ngIf="verConectados" class="my-scrollbar my-scrollbar-clear">
            <li>
                <button (click)="chatService.abrir()" class="boton__abrir__chat">
                    {{ 'networking.iniciar_chat' | translate }}
                </button>
            </li>
            <ng-container *ngFor="let usuarioId of chatService.listado | chatOrdenado : 'solo-chat'">
                <li [class.leido]="chatService.estaLeido(usuarioId)" (click)="chatService.abrir(usuarioId)" class="chat__iniciado">
                    <span>{{ usuarioId | usuario }}<!--<span class="num-msg"> (7)</span>--></span>
                    <svg-icon class="contenedor-svg-mensaje" src="assets/imgs/usuario/msg.svg"></svg-icon>
                </li>
            </ng-container>
        </ul>
    </div>

</section>




