<section drag-and-drop-chat class="net-chat ventana__emergente">

    <div class="net-chat__action">
        <svg-icon class="mover" id="mover-chat" src="assets/imgs/networking/chat/move.svg"></svg-icon>
        <svg-icon class="cerrar" (click)="cerrar.emit()" src="assets/imgs/networking/chat/close.svg"></svg-icon>
    </div>
    <div class="net-chat__grid">

        <!-- LISTADO DE CONVERSACIONES Y USUARIOS -->
        <div class="net-chat__contacts">
            <svg-icon src="assets/imgs/comun/buscar.svg"></svg-icon>
            <input (search)="filtrarUsuarios($event.target)" (keyup)="filtrarUsuarios($event.target)" class="net-chat__find" type="search" placeholder="Buscar o empezar un chat nuevo">
            <ul class="net-chat__people my-scrollbar">
                <ng-container *ngFor="let usuarioId of chatService.listado | chatOrdenado">
                    <li (click)="abrirChat(usuarioId)"
                        [ngClass]="{ seleccionado: usuarioId == chatService.idOtroUsuario, chat__pendiente_leer: !chatService.estaLeido(usuarioId) }">
                        
                        {{ usuarioId | usuario }}

                        <p class="empresa">{{ usuarioId | usuario : 'nombreEmpresa' }}</p>
                        <p class="centro-trabajo">{{ usuarioId | usuario : 'centro_trabajo' }}</p>

                        <span *ngIf="api.usuario.chats[usuarioId]" class="ultimo__mensaje__chat" [innerHTML]="api.usuario.chats[usuarioId].mensaje | safeHtml"></span>
                    </li>
                </ng-container>
            </ul>
        </div>

        <!-- CONVERSACIÓN -->
        <ng-container>
            <div #scrollMe class="net-chat__conversation my-scrollbar my-scrollbar-square">
                <ng-container *ngIf="chatService.idOtroUsuario">
                    <div class="mensaje__empieza__a__chatear" *ngIf="mensajesChat.length == 0 && !cargandoChat">
                        <p class="sin__mensajes">{{ 'chat.sin_mensajes.titulo' | translate }}</p>
                        <span class="chatea__con">{{ 'chat.sin_mensajes.chatea_con' | translate : { nombre: chatService.idOtroUsuario | usuario } }}</span>
                    </div>
                    <div class="contenedor__mensaje" *ngFor="let mensaje of mensajesChat; let i = index;">
                        <div *ngIf="hayCambioDeFecha(i)" class="fecha__mensaje">
                            <span>{{ mensaje.created_at | date : 'd MMMM' }}</span>
                        </div>
                        <div id="{{ mensaje.id }}" [class]="mensaje.remitente_id == chatService.idOtroUsuario ? 'chat__org' : 'chat__user'">
                            <div class="chat__rol">

                                <span class="me chat__name mr-2">{{ 'chat.yo' | translate }}</span>
                                <svg-icon class="chat__icon" src="assets/imgs/networking/chat/user.svg"></svg-icon>
                                <span class="other chat__name ml-2">{{ chatService.idOtroUsuario | usuario }}</span>

                            </div>
                            <p class="chat__msg"
                               [ngClass]="{ 'ocultar-btn-one-to-one': mensaje.destinatario_id == chatService.idOtroUsuario }"
                               [innerHTML]="mensaje.mensaje | safeHtml">
                            </p>

                            <a *ngIf="mensaje.mensaje.startsWith('<h1 class')"
                               (click)="gestionarCalendario()"
                               role="button">
                                {{ 'chat.gestionar_one_to_one' | translate }}
                            </a>

                            <p class="chat__time">
                                <ng-container *ngIf="!mensajeDeHoy(mensaje.created_at)">
                                    {{ mensaje.created_at | date : 'd MMMM' }}
                                </ng-container>
                                {{ mensaje.created_at | date : 'HH:mm' }}
                            </p>
                        </div>
                    </div>
                </ng-container>
            </div>
            <div class="net-chat__writte d-flex justify-content-center align-items-center">
                <ng-container *ngIf="chatService.idOtroUsuario">
                    <input (keyup.enter)="enviarMensaje()" [(ngModel)]="textoMensaje" type="text">
                    <button (click)="enviarMensaje()" class="net-chat__send">
                      <svg-icon src="assets/imgs/stand1/enviar.svg"></svg-icon>
                    </button>
                </ng-container>
            </div>
        </ng-container>
    </div>

</section>
