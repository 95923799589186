import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AvatarPipe } from './avatar/avatar.pipe';
import { EmpresaPipe } from './empresa/empresa.pipe';
import { IframePipe } from './iframe/iframe.pipe';
import { NombreCompletoPipe } from './nombre-completo/nombre-completo.pipe';
import { ChatOrdenadoPipe } from './object/chatOrdenado.pipe';
import { KeysPipe } from './object/keys.pipe';
import { ValuesPipe } from './object/values.pipe';
import { SafeHtmlPipe } from './safe-html/safe-html.pipe';
import { SafeUrlPipe } from './safe-url/safe-url.pipe';
import { SalaPipe } from './sala/sala.pipe';
import { UsuarioChatPipe } from './usuario-chat/usuario-chat.pipe';
import { UsuarioPipe } from './usuario/usuario.pipe';
import { FechasPipe } from './fechas/fechas.pipe';
import { PonentePipe } from './ponentes/ponente.pipe';

@NgModule({
  declarations: [
    UsuarioChatPipe,
    SafeHtmlPipe,
    SafeUrlPipe,
    SalaPipe,
    UsuarioPipe,
    AvatarPipe,
    NombreCompletoPipe,
    EmpresaPipe,
    ChatOrdenadoPipe,
    ValuesPipe,
    KeysPipe,
    IframePipe,
    FechasPipe,
    PonentePipe
  ],
  exports: [
    UsuarioChatPipe,
    SafeHtmlPipe,
    SafeUrlPipe,
    SalaPipe,
    UsuarioPipe,
    AvatarPipe,
    NombreCompletoPipe,
    EmpresaPipe,
    ChatOrdenadoPipe,
    ValuesPipe,
    KeysPipe,
    IframePipe,
    FechasPipe,
    PonentePipe
  ],
  imports: [
    CommonModule
  ]
})
export class PipeModule { }
