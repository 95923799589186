import { Pipe, PipeTransform } from '@angular/core';
import { Usuario } from '../../models/usuario/usuario';

const AVATAR_POR_DEFECTO = 'assets/imgs/comun/avatar-defecto.svg';

@Pipe({
  name: 'avatar',
  pure: false
})
export class AvatarPipe implements PipeTransform {

  transform(usuario: Usuario): string {
    return usuario.avatar || usuario.usuario.avatar || AVATAR_POR_DEFECTO;
  }

}
