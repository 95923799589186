import { AreaTematica } from '../area-tematica/area-tematica';
import { Multimedia } from '../multimedia/multimedia';
import { Imagen } from './imagen';
import { Autor } from './autor/autor';
import { IPonente } from '../ponente/ponente';

export class DetalleSesion {

  html_extra: any;
  html_extra_alt: any;
  audios :any;
  id !: number;
  area_tematica_evento_id !: number;
  contenido_diferido !: boolean;
  orden !: number;
  titulo !: string;
  descripcion !: string;
  imagen_perfil_autor !: Imagen[];
  autores !: IPonente[];
  enlace !: string;
  multimedia !: Multimedia[];
  areas_tematicas !: AreaTematica[];
  imagen_perfil_moderador !: { [id: number]: Imagen };
  valoracion !: number;
  valoraciones !: number;
  htmls_extra !: any[];
  miniatura?: string;

  constructor(dto: IDetalleSesionDTO) {
    Object.assign(this, dto);
  }

  // Comentado el 30-nov-2022 por innecesario, se configuran en el servicio de traducción estos atributos
  // get html_extra(): string {
  //   return this.htmls_extra.find( ({ nombre }) => nombre === 'html_extra' )?.html || '';
  // }

  // set html_extra(html: string) {}
}

export interface IDetalleSesionDTO {

  audios: any;
  id: number;
  area_tematica_evento_id: number;
  contenido_diferido: boolean;
  orden: number;
  titulo: string;
  descripcion: string;
  imagen_perfil_autor: Imagen[];
  autores: Autor[];
  enlace: string;
  htmls_extra: any[];
  miniatura?: string;
  multimedia: Multimedia[];
  areas_tematicas: AreaTematica[];
  imagen_perfil_moderador: { [id: number]: Imagen };
  valoracion: number;
  valoraciones: number;
}
