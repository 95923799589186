<app-banner-title banner="broadcast.titulo">
  <section id="difusion">

    <div class="body">
      <div class="mb-3 on-site-form">
        <label class="on-site-form-label" for="id-broadcast-msg">
          {{ 'broadcast.label' | translate }}
        </label>
        <textarea
          [(ngModel)]="texto"
          class="on-site-form-control my-scrollbar my-scrollbar-clear"
          id="id-broadcast-msg">
        </textarea>
      </div>

      <div class="btn-container">
        <button
          class="btn-on-site btn-primary-light"
          [disabled]="!texto.trim() || enviando"
          (click)="publicar()">

          <span *ngIf="enviando" class="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>

          {{ 'broadcast.enviar' | translate }}
          
        </button>
      </div>
    </div>

  </section>
</app-banner-title>