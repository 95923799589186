import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { NetworkingChatModule } from '../pages/emergente/networking-chat/networking-chat.module';
import { PipeModule } from '../pipes/pipe.module';
import { AlertasComponent } from './alertas/alertas.component';
import { MenuLateralComponent } from './menu-lateral/menu-lateral.component';
import { MultimediaComponent } from './multimedia/multimedia.component';
import { NavbarComponent } from './navbar/navbar.component';
import { NetworkComponent } from './network/network.component';
import { CerrarEmergenteComponent } from './resources/cerrar-emergente/cerrar-emergente.component';
import { CerrarFancyComponent } from './resources/cerrar-fancy/cerrar-fancy.component';
import { DifusionComponent } from './broadcast/broadcast.component';
import { FormsModule } from '@angular/forms';
import { ComponentsModule } from '../components/components.module';

@NgModule({
  declarations: [
    NavbarComponent,
    NetworkComponent,
    MultimediaComponent,
    CerrarFancyComponent,
    CerrarEmergenteComponent,
    MenuLateralComponent,
    AlertasComponent,
    DifusionComponent
  ],
  exports: [
    NavbarComponent,
    NetworkComponent,
    MultimediaComponent,
    CerrarFancyComponent,
    CerrarEmergenteComponent,
    MenuLateralComponent,
    AlertasComponent,
    DifusionComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    TranslateModule,
    PipeModule,
    FormsModule,
    NetworkingChatModule,
    ComponentsModule,
    AngularSvgIconModule
  ]
})
export class SharedModule { }
