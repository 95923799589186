export const fondos   : { [key: string]: string } = {
  auditorio            : 'assets/imgs/sala-principal/auditorio.jpg',
  bienvenida           : 'assets/imgs/fondos/bienvenida_por_defecto.jpg',
  eposters             : 'assets/imgs/fondos/eposters_por_defecto.jpg',
  exposicion_comercial : 'assets/imgs/fondos/exposicion_comercial_por_defecto.jpg',
  hall                 : 'assets/imgs/fondos/hall_por_defecto.jpg',
  login                : 'assets/imgs/fondos/login_por_defecto.jpg',
  networking           : 'assets/imgs/fondos/networking_por_defecto.jpg',
  patrocinadores       : 'assets/imgs/patrocinadores/fondo-patrocinadores.jpg',
  ponentes             : 'assets/imgs/ponentes/fondo_ponentes.jpg',
  stand                : 'assets/imgs/fondos/stand_por_defecto.jpg',
  videos               : 'assets/imgs/videos/fondo_videos.jpg'
}
