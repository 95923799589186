import { Component, isDevMode } from '@angular/core';
import { ApiService } from '../../services/api.service';
import { ChatService } from '../../services/chat.service';

@Component({
    selector: 'app-network',
    templateUrl: './network.component.html',
    styleUrls: ['./network.component.scss']
})
export class NetworkComponent {

    flechaChat: 'up' | 'down' = 'down';
    verConectados: boolean = false;

    pendientes: number = 0;

    constructor(
        public api: ApiService,
        public chatService: ChatService
    ) {
      if ( isDevMode() ) console.log( '-- Network Component --' );
    }

    cerrarChat(): void {
      this.chatService.cerrar();
      this.desplegarContactos({ accion: 'cerrar' });
    }

    desplegarContactos(opciones?: { accion: 'abrir' | 'cerrar' }): void {
        if ( opciones?.accion == 'abrir' ) {
            this.verConectados = true;
            this.flechaChat = 'up';
        } else if ( opciones?.accion == 'cerrar' ) {
            this.verConectados = false;
            this.flechaChat = 'down';
        } else {
            this.verConectados = !this.verConectados;
            this.flechaChat = this.flechaChat === 'up' ? 'down' : 'up';
        }
    }

}
