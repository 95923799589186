import { Pipe, PipeTransform } from '@angular/core';
import { Empresa } from '../../models/evento/empresa/empresa';
import { DatosUsuario } from '../../models/usuario/datos-usuario/datos-usuario';
import { Usuario } from '../../models/usuario/usuario';
import { UsuarioService } from '../../services/usuario.service';

@Pipe({
  name: 'usuario',
  pure: false
})
export class UsuarioPipe implements PipeTransform {

  constructor( private usuarioService: UsuarioService ) {
    
  }

  transform(
      id: number | string | undefined,
      atributo: 'nombreCompleto' | 'nombreEmpresa' | 'centro_trabajo' = 'nombreCompleto'
  ): string {
    const usuario = this.usuarioService.listado.find( us => us.id == id );

    return usuario ? this.obtenerResultado(usuario, atributo) : '...';
  }

  private obtenerResultado(usuario: Usuario, atributo: 'nombreCompleto' | 'nombreEmpresa' | 'centro_trabajo'): string {
    switch (atributo) {
      case 'nombreCompleto': return this.obtenerNombreCompleto(usuario.usuario);
      case 'nombreEmpresa': return this.obtenerNombreEmpresa(usuario.empresa);
      case 'centro_trabajo': return this.obtenerCentroTrabajo(usuario);
    }
  }

  private obtenerNombreCompleto(datos: DatosUsuario): string {
    return datos.nombre + ' ' + datos.apellidos;
  }

  private obtenerNombreEmpresa(empresa: Empresa | undefined): string {
    return empresa ? empresa.nombre : ' ';
  }

  private obtenerCentroTrabajo(usuario: Usuario): string {
    return usuario?.centro_trabajo || '';
  }

}
