import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { fondos } from '../configs/fondos.configs';
import { Language } from '../models/api/lang.type';
import { Multimedia } from '../models/evento/multimedia/multimedia';
import { Sala } from '../models/evento/sala/sala';
import { Sesion } from '../models/evento/sesion/sesion';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class FondosService {

  private readonly lang: Language;
  private readonly multimedia: Multimedia[];

  constructor( api: ApiService, translate: TranslateService ) {
    this.lang = <Language> translate.currentLang;
    this.multimedia = api.evento.multimedia;
  }

  obtenerFondo(key: string): string {
    const filename = this.lang === 'es' ? key : `${ this.lang}.${ key }`;

    return this.multimedia.find(
        m => m.nombre === filename
      )?.url || this.multimedia.find(
        m => m.nombre === key
      )?.url || fondos[key];
  }

  obtenerFondoStand(multimedia: Multimedia[]): string {
    const key = 'fondo';
    const filename = this.lang === 'es' ? key : `${ this.lang}.${ key }`;

    return multimedia.find(
        m => m.nombre?.startsWith(filename)
      )?.url || multimedia.find(
        m => m.nombre?.startsWith(key)
      )?.url || fondos.stand;
  }

  obtenerAuditorio(sala?: Sala, sesion?: Sesion) {
    const key = 'auditorio';
    const filename = this.lang === 'es' ? key : `${ this.lang}.${ key }`;

    return sesion?.multimedia.find(
        m => m.nombre === filename
      )?.url || sala?.multimedia.find(
        m => m.nombre === filename
      )?.url || this.multimedia.find(
        m => m.nombre === filename
      )?.url || sesion?.multimedia.find(
        m => m.nombre === key
      )?.url || sala?.multimedia.find(
        m => m.nombre === key
      )?.url || this.multimedia.find(
        m => m.nombre === key
      )?.url || fondos.auditorio;
  }
}
