<!-- <div *ngIf="volver" class="volver sombra mt-2">
    <a [routerLink]="volver.ruta">
        <svg-icon class="mr-2" src="assets/imgs/comun/flecha-larga.svg"></svg-icon>
        {{ volver.texto | translate }}
    </a>
</div> -->

<nav class="usuario__nav sombra">

    <app-cerrar-fancy *ngIf="btnCerrar" (cerrar)="cerrar.emit()"></app-cerrar-fancy>

    <ul class="mt-2">
        <li *ngIf="volver" [routerLink]="volver.ruta" id="li-volver">
            <!-- <svg-icon [src]="opcion.svg" class="contenedor__svg" [class.activo]="opcion.activo && !opcion.link"></svg-icon> -->
            <!-- <span [ngClass]="{ azul: opcion.activo && !opcion.link }">{{ opcion.texto | translate }}</span> -->
            <svg-icon class="contenedor__svg" src="assets/imgs/comun/flecha-larga.svg"></svg-icon>
            <span>{{ volver.texto | translate }}</span>
        </li>
        
        <ng-container *ngFor="let opcion of opciones | values; let i = index;">
            <li class="opciones-menu" *ngIf="!opcion.ocultar" (click)="seleccionarOpcion(i)">
                <svg-icon [src]="opcion.svg" class="contenedor__svg" [class.activo]="opcion.activo && !opcion.link"></svg-icon>
                <span [ngClass]="{ azul: opcion.activo && !opcion.link }">{{ opcion.texto | translate }}</span>
            </li>
        </ng-container>
    </ul>
</nav>

<div *ngIf="separador"
     (click)="cerrar.emit()"
     class="ventana__emergente__separador">
</div>
