import { Component, Input } from '@angular/core';
import { ApiService } from '../../services/api.service';

@Component({
  selector: 'app-falso-pop-up',
  templateUrl: './falso-pop-up.component.html',
  styleUrls: ['./falso-pop-up.component.scss']
})
export class FalsoPopUpComponent {

  @Input() background: string = '#fff';
  @Input() titulo: string = '';
  @Input() tituloCompleto: boolean = false;

  constructor(public readonly api: ApiService) {}

}
