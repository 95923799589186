<section id="falso-pop-up" class="pt-4 my-scrollbar" [ngStyle]="{ background: background }">

    <ng-content select=[sidebar]></ng-content>

    <div class="container container-tabla-mobile">

        <div class="ventana-falso-pop-up">
            <div class="titulo">
                <h1>
                    {{ 'emergente.congreso_virtual' | translate }} | <span>{{ api.evento | fechas }}</span>
                </h1>
            </div>

            
            <h2 *ngIf="tituloCompleto else bandaCompuesta" class="titulo-completo">
                {{ titulo | translate }}
            </h2>

            <ng-template #bandaCompuesta>
                <div class="banda">
                    <h2 class="titulo-banda">
                        {{ titulo | translate }}
                    </h2>
                    <div class="contenido">
                        <ng-content select=[banda]></ng-content>
                    </div>
                </div>
            </ng-template>

            <ng-container>
                <ng-content select="[contenido]"></ng-content>
            </ng-container>
        </div>
    </div>

</section>
