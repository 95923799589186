export class UsuarioChat {

  constructor(
    public id: number,
    public nombre: string,
    public apellidos: string
  ) { }

  obtenerNombreYApellido(): string {
    const apellidos: string[] = this.apellidos.split(' ');
    return this.nombre + ' ' + apellidos[0];
  }

  contieneCadena(filtro: string): boolean {
    const filtroMinusculas: string = filtro.toLowerCase();
    const completo: string = (this.nombre + ' ' + this.apellidos).toLowerCase();

    return completo.includes( filtroMinusculas );
  }
}
