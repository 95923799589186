import { Component, EventEmitter, Input, isDevMode, Output } from '@angular/core';
import { OpcionMenu, OpcionesMenu } from '../../models/common/opcion-menu';
import { Volver } from '../../models/common/volver';

@Component({
  selector: 'app-menu-lateral',
  templateUrl: './menu-lateral.component.html',
  styleUrls: ['./menu-lateral.component.scss']
})
export class MenuLateralComponent {

  @Input() btnCerrar: boolean = true;
  @Input() opciones: OpcionesMenu = {};
  @Input() separador: boolean = true;
  @Input() volver?: Volver;

  @Output() cerrar = new EventEmitter<void>();
  @Output() seleccionar = new EventEmitter<string>();

  constructor() {
    if ( isDevMode() ) console.log('-- MenuLateralComponent --');
  }

  seleccionarOpcion(indice: number): void {
    const entries = Object.entries(this.opciones);
    const key: string = entries[indice][0];
    
    this.seleccionar.emit(key);

    Object.values( 
      this.opciones
    ).forEach( 
      (opcion: OpcionMenu, i: number) => opcion.activo = indice == i ? !opcion.activo : false
    );
  }
}
