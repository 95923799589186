import { Component, isDevMode, OnInit } from '@angular/core';
import { Router, RouterOutlet } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import * as AOS from 'aos';
import { routeTransitionAnimations } from './animations/route-transition-animations';
import { AlertService } from './services/alert.service';
import { HelperService } from './services/helper.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [routeTransitionAnimations]
})
export class AppComponent implements OnInit {

  constructor(
    public helper: HelperService,
    private alertService: AlertService,
    private translate: TranslateService,
    private router: Router
  ) { }

  prepareRoute(outlet: RouterOutlet): any {
    return outlet && outlet.activatedRouteData && outlet.activatedRouteData['animationState'];
  }

  ngOnInit() {
    AOS.init();

    this.insertarListenerEjecutadorRouterLink();    
    this.insertarListenerSweetAlert();    

    this.mostrarAvisoSiSeAccedeDesdeUnMovil();
  }

  /**
   * Esta función escucha los window.postMessage que se ejecuten del script del panel de control con el type 'router-link'.
   * De esta manera se puede navegar entre pantallas de una manera más suave a través del routerLink de Angular y sin utilizar el href.
   */
  private insertarListenerEjecutadorRouterLink() {
    window.addEventListener('message', (event) => {
      if ( event.origin === window.origin && event.type === 'message' && event.data.type === 'router-link' ) {
        if ( isDevMode() ) console.warn('Navegando router link ejecutado por script del panel de control', event.data.data);
        this.router.navigate([event.data.data]);
      }
    });
  }

  private insertarListenerSweetAlert() {
    window.addEventListener('message', (event) => {
      if ( event.origin === window.origin && event.type === 'message' && event.data.type === 'sweet-alert-2' ) {
        const { options, responses } = event.data;
        this.alertService.showSweetAlert(options, responses);
      }
    });
  }

  private mostrarAvisoSiSeAccedeDesdeUnMovil(): void {
    const tag: string = 'alerta_acceso_mobile';

    if ( this.helper.mobile && !localStorage.getItem(tag) ) {
      setTimeout( () => {
        localStorage.setItem(tag, tag);
        this.translate.get('alerta.mobile').subscribe( alert );
      }, 1000);
    }
  }
}

