import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { Respuesta } from 'src/app/models/api/api';
import { environment } from '../../environments/environment';
import { Chat, ChatParcial } from '../models/chat/chat';
import { ListadoUsuarios } from '../models/chat/listado';
import { UsuarioChat } from '../models/chat/usuario-chat';
import { Usuario } from '../models/usuario/usuario';
import { ApiService } from './api.service';
import { SocketService } from './socket.service';
import { UsuarioService } from './usuario.service';

const URL = environment.api + '/chat/';

@Injectable({
  providedIn: 'root'
})
export class ChatService {

  idOtroUsuario ?: number | null;

  private _abierto  : boolean = false;
  private _usuarios : ListadoUsuarios = {
    listado: [],
    termino: ''
  };

  constructor(
    private api: ApiService,
    private http: HttpClient,
    private socket: SocketService,
    private usuarioService: UsuarioService
  ) {}

  get abierto(): boolean {
    return this._abierto;
  }

  get pendientes(): number {
    return Object.keys(
      this.api.usuario.chats
    ).filter(
      idOtroUsuario => !this.estaLeido(+idOtroUsuario)
    ).length;
  }

  get listado(): number[] {
    return this._usuarios.listado.filter( 
      usuario => usuario.contieneCadena(this._usuarios.termino)
    ).map( 
      usuario => usuario.id
    );
  }

  init() {
    this.cargarUsuarios();
    this.obtenerMensaje().subscribe( (chat: Chat) => this.api.usuario.chats[chat.remitente_id] = chat );
  }

  abrir(idOtroUsuario ?: number | null) {
    this.idOtroUsuario = idOtroUsuario;

    if ( this.api.usuario.id === idOtroUsuario ) {
      this.idOtroUsuario = null;
    } else {
      this._abierto = true;
    }
  }

  cerrar() {
    this.idOtroUsuario = null;
    this._abierto = false;
  }

  difundir(mensaje: string): Observable<Chat[]> {
    const chatCompletion = { 
      mensaje,
      remitente_id: this.api.usuario.id,
      leido: null
    };

    return this.http.post<Respuesta<ChatParcial[]>>(URL + 'difusion', { mensaje }).pipe(

      map( ({ mensajes }) => mensajes.map( (msj: any) => {
        const chat: Chat = Object.assign(msj, chatCompletion);

        this.api.usuario.chats[chat.destinatario_id] = chat;
        this.socket.enviarMensajeChat(chat);

        return chat;
      })

    ));
  }

  estaLeido(idOtroUsuario: number): boolean {
    const chat: Chat | undefined = this.api.usuario.chats[idOtroUsuario];
    return !chat || chat.leido || chat.destinatario_id === idOtroUsuario;
  }

  eliminarMensaje(idOtroUsuario: number, idMensaje: number): Observable<any> {
    return this.http.post( URL + idOtroUsuario + '/eliminar', { mensaje_id: idMensaje } );
  }

  filtrar(termino: string) {
    this._usuarios.termino = termino;
  }

  listarMensajesChat(idOtroUsuario: number): Observable<Chat[]> {
    return this.http.get< Respuesta<Chat[]> >(URL + idOtroUsuario).pipe(
        map( ({ chats }: Respuesta<Chat[]>) => chats.map( chat => Object.assign(chat, { idOtroUsuario }) ) ),
        map( (chats: Chat[]) => chats.sort( (c1, c2) => new Date(c1.created_at) > new Date(c2.created_at) ? 1 : -1 ) )
    );
  }

  marcarComoLeido(idOtroUsuario: number) {
    const chat: Chat | undefined = this.api.usuario.chats[idOtroUsuario];

    if ( chat && !chat.leido && chat.remitente_id === idOtroUsuario ) { 
      this.http.post(URL + idOtroUsuario + '/leido', { mensaje_id: chat.id }).pipe(
        tap( () => this.api.usuario.chats[ idOtroUsuario ].leido = true )
      ).subscribe();
    }
  }

  publicarMensaje(idOtroUsuario: number, mensaje: string): Observable<Chat[]> {
    return this.http.post< Respuesta<Chat[]> >(URL + idOtroUsuario, {
      mensaje: mensaje
    }).pipe(
      map( ({ chats }: Respuesta<Chat[]>) => chats.map( chat => Object.assign(chat, { idOtroUsuario }) ) ),
      map( (chats: Chat[]) => chats.sort( (c1, c2) => new Date(c1.created_at) > new Date(c2.created_at) ? 1 : -1 ) ),
      tap( (chats: Chat[]) => this.socket.enviarMensajeChat(chats[ chats.length - 1]) )
    );
  }

  obtenerMensaje(): Observable<Chat> {
    return this.socket.obtenerMensajeChat();
  }

  private cargarUsuarios() {
    this.usuarioService.usuarios$.pipe(
      map( 
        (usuarios: Usuario[]) => usuarios.map( usuario => new UsuarioChat(usuario.id, usuario.usuario.nombre, usuario.usuario.apellidos) )
      )
    ).subscribe(
       usuarios => this._usuarios.listado = usuarios.filter( us => us.id !== this.api.usuario.id && us )
    );
  }

}
