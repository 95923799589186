export const tokens: any  = {

  // Desarrollo / Test
  'localhost'                                  : 'YfvSKGenlZ8SGC1bIBsoitd6ZE2Z7YCjxC5PK5PqfyaN5S7DaPhQ4fwJ5myC',
  'pruebas.onsitevents.com'                    : 'FlvPrsZqM4OVN6JK9IMS4LThi2AJcgzdFfnIL5vfi5yxVYrTthCA7rBMe1x2',

  // Producción
  'congresosefmsepr2023.onsitevents.com'       : 'YfvSKGenlZ8SGC1bIBsoitd6ZE2Z7YCjxC5PK5PqfyaN5S7DaPhQ4fwJ5myC', // 23-05-2023 | 24-05-2022
  '30simposiottd.onsitevents.com'              : 'rFeXhGWEKiCbmCDdFEDWwxpoXx8HKkHOOmOMNQEM2lS8KgI6mdSCMNPAngYU', // 15-12-2022 | 16-12-2022
  'jornadas22online.onsitevents.com'           : 'ANsxcIMtlyhuUUMT0jbjhsKpOLgAqMJfU3BGONv8BkruNQe2fQD5Nkom7Wwt', // 06-09-2022 | 08-09-2022
  'icmi2022online.onsitevents.com'             : 'rnYm4Q8QWqo8GH2xv0C6RX2CwMUjpQ6WzUIEtuDluFgzejgPeuW0e9eQyZR8', // 13-06-2022 | 16-06-2022
  'congresosaludcantabria2022.onsitevents.com' : 'cSOpWcAUIOdgs7ekfv8Evcioa2h2OCysdDQSKw1w5Dx85JdRqufJKYF1UhVz', // 17-05-2022 | 17-05-2022
  'medicinaprecision22.onsitevents.com'        : 'ZYOudzX7HLXwSIPHv8Bu29gmJWspaXVJvKgMkmOnX0Kw53aZ82hL5wGZWf9o', // 16-03-2022 | 16-03-2022
  'congresosorlv2022.onsitevents.com'          : 'iclngrKoBAoJ1sM5jJqom9YOxQrNGHUOdb1paokGyEL23pRfBwki2kEjSSyI', // 03-02-2022 | 04-02-2022
  '29simposiottd.onsitevents.com'              : 'cAraQ21pG8V0k65TNaj5O28cMewg6K73xGi5iwhjyuvGU01gUlp9NpElunfj', // 15-12-2021 | 17-12-2021
  'aitana2021online.onsitevents.com'           : 'u8nz8nm7AgV8A2oUWU45YqEK1wXvKVQ3qgJTYN6QU4pxWCiZx2K4E0T8e2re', // 18-11-2021 | 20-11-2021
  'secavirtual2021.onsitevents.com'            : 'f7hy0FDHPiVVm9Ji6iP6F9272lsLfhU3zXCOWzQaiRoGSdAk1MbZlZPfe6ac', // 18-09-2021 | 28-09-2021
  'js21online.onsitevents.com'                 : 'Js6PN1ZBwjMgYEqUiwVaNH5yjYAh3ueCPFOGKMlDTSsC9bdwIn4FhF6aPAC9', // 21-09-2021 | 23-09-2021
  'xsimposioaev.onsitevents.com'               : 'ZBS9x6nR5YMzE9UlUW9igMUIUeuKPjYDK4qtfLUFxOESeDOVktGQMd6usoet', // 19-09-2021 | 22-09-2021
  'senfcvirtual2021.onsitevents.com'           : 'Xikw2SDIoD9pqK8X8zm3x43jm0JFVKJ5VO1IraE08MmwjBWlEmCKgdPSZ8gW', // 14-09-2021 | 16-09-2021
  'plataforma2021.congresosefmsepr.es'         : 'yKaZ27gn8SlOm3VElWJRCXoezBZGskgNSiAQGvTC4ERCVXbV0KUxeJB9cMWm', // 31-05-2021 | 04-06-2021
  
}
