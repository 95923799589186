import { Pipe, PipeTransform } from '@angular/core';
import { Usuario } from '../../models/usuario/usuario';

@Pipe({
  name: 'nombreCompleto'
})
export class NombreCompletoPipe implements PipeTransform {

  transform(usuario: Usuario): unknown {
    return usuario.usuario.nombre + ' ' + usuario.usuario.apellidos;
  }

}
