import { Component, Input } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-banner-title',
  templateUrl: './banner-title.component.html',
  styleUrls: ['./banner-title.component.scss']
})
export class BannerTitleComponent {

  @Input() banner : string = '';

  constructor( public api: ApiService ) { }

}
