import { Component, EventEmitter, isDevMode, Output } from '@angular/core';

@Component({
  selector: 'app-cerrar-fancy',
  templateUrl: './cerrar-fancy.component.html',
  styleUrls: ['./cerrar-fancy.component.scss']
})
export class CerrarFancyComponent {

  @Output() cerrar = new EventEmitter<void>();

  constructor() {
    if ( isDevMode() ) console.log('-- CerrarFancyComponent --');
  }
  
}
