import { TextoLegal } from './texto-legal/texto-legal';
import { Registro } from './registro/registro';
import { Empresa } from './empresa/empresa';
import { Secretaria } from './secretaria/secretaria';
import { Sociedad } from './sociedad/sociedad';
import { Multimedia } from './multimedia/multimedia';
import { ServidorSmtp } from './servidor-smpt/servidor-smtp';
import { AreaTematica } from './area-tematica/area-tematica';
import { TipoSesion } from './sesion/tipo-sesion';
import { ISalaDTO, Sala } from './sala/sala';
import { ISesionDTO, Sesion } from './sesion/sesion';
import { Enlace } from './enlace/enlace';
import { TipoRegistro } from './tipo-registro/tipo-registro';
import { Language } from '../api/lang.type';
import { Video } from './video/video.model';
import { Contenido } from './contenidos/contenido.interface';

export class Evento {

  analytics?: string;
  contenidos!: Contenido[];
  acompanyantes_por_usuario !: number;
  activo !: boolean;
  id !: number;
  nombre !: string;
  subtitulo !: string;
  lema !: string;
  descripcion !: string;
  networking_asistentes !: number;
  networking_asistentes_patrocinadores !: number;
  eposter !: boolean;
  eposter_url !: string;
  eposter_requiere_login !: number;
  eposter_votaciones ?: boolean;
  eposter_comunicacion_con_autor ?: boolean;
  textos_legales !: TextoLegal[];
  campos_registro !: Registro[];
  empresas !: Empresa[];
  fecha_inicio !: string;
  fecha_fin !: string;
  sede !: string;
  ciudad !: string;
  aforo !: number;
  aforo_acompanyantes !: number;
  servidor_smtp_id !: number;
  frontend_colores !: string;
  frontend_css !: string;
  frontend_url !: string;
  requiere_login !: boolean;
  datos_secretaria !: Secretaria;
  datos_sociedad !: Sociedad;
  tipo_creditos !: string;
  creditos !: any;
  asistencia_minima !: any;
  enlace !: string;
  enlaces !: Enlace[];
  multimedia !: Multimedia[];
  servidor_smtp !: ServidorSmtp;
  areas_tematicas !: AreaTematica[];
  salas: Sala[];
  sesiones !: Sesion[];
  tipos_registro !: TipoRegistro[];
  tipos_sesion !: TipoSesion[];
  idiomas !: Language[];

  videos: Video[];

  constructor(iEvento: IEventoDTO) {
    Object.assign(this, iEvento);

    this.salas = iEvento.salas.sort( (s1, s2) => s1.orden - s2.orden ).map( sala => new Sala(sala) );
    this.sesiones = iEvento.sesiones.sort( (e1, e2) => new Date(e1.inicio).getTime() - new Date(e2.inicio).getTime() ).map( sesion => new Sesion(sesion) );
    
    this.videos = this.cargarVideos(this.sesiones)
  }

  private cargarVideos(sesiones: Sesion[]): Video[] {
    const videos: Video[] = [];

    sesiones.forEach( sesion => {
      const video = new Video(sesion);
      const videosDetalleSesion = sesion.detalles.map( detalle => new Video(sesion, detalle) );
      
      videos.push(video);
      videos.push(...videosDetalleSesion);
    });

    return videos;
  }

  get estilos() {
    
    try {
      return JSON.parse( this.frontend_colores ).premium || {};
    } catch (error) {
      return {};
    }

  }

  get idioma(): Language {
    let langEv = this.estilos.lang || 'es';

    const storage = localStorage.getItem('lang');
    if ( storage && ['en', 'es'].includes(storage) ) {
      langEv = storage;
    }

    return langEv;
  }

  get favicon(): string | undefined {
    return this.multimedia.find( multi => multi.url.includes('favicon') )?.url;
  }
}

export interface IEventoDTO {
  analytics?: string;
  contenidos: any;
  acompanyantes_por_usuario: number;
  activo: boolean;
  id: number;
  nombre: string;
  subtitulo: string;
  lema: string;
  descripcion: string;
  networking_asistentes: number;
  networking_asistentes_patrocinadores: number;
  eposter: boolean;
  eposter_url: string;
  eposter_requiere_login: number;
  eposter_votaciones?: boolean;
  eposter_comunicacion_con_autor?: boolean;
  textos_legales: TextoLegal[];
  campos_registro: Registro[];
  empresas: Empresa[];
  fecha_inicio: string;
  fecha_fin: string;
  sede: string;
  ciudad: string;
  idiomas: Language[];
  aforo: number;
  aforo_acompanyantes: number;
  servidor_smtp_id: number;
  frontend_colores: string;
  frontend_css: string;
  frontend_url: string;
  requiere_login: boolean;
  datos_secretaria: Secretaria;
  datos_sociedad: Sociedad;
  tipo_creditos: string;
  creditos: any;
  asistencia_minima: any;
  enlace: string;
  enlaces: Enlace[];
  multimedia: Multimedia[];
  servidor_smtp: ServidorSmtp;
  areas_tematicas: AreaTematica[];
  salas: ISalaDTO[];
  sesiones: ISesionDTO[];
  tipos_registro: TipoRegistro[];
  tipos_sesion: TipoSesion[];

  estilos: any;
  favicon: string | undefined;
  idioma: Language;
}

export interface ApiEvento {
  evento: IEventoDTO;
}