import {
  Component,
  ElementRef,
  EventEmitter, isDevMode,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { Chat } from '../../../models/chat/chat';
import { ApiService } from '../../../services/api.service';
import { ChatService } from '../../../services/chat.service';
import { ComunicacionInternaService } from '../../../services/comunicacion-interna.service';

@Component({
    selector: 'app-networking-chat',
    templateUrl: './networking-chat.component.html',
    styleUrls: ['./networking-chat.component.scss']
})
export class NetworkingChatComponent implements OnInit, OnDestroy {

  @Output() cerrar = new EventEmitter<void>();
  @ViewChild('scrollMe') private myScrollContainer!: ElementRef;

  textoMensaje: string = '';

  mensajes$?: Subscription;
  socket$: Subscription;

  // Chats del usuario
  cargandoChat: boolean = false;
  mensajesChat: Chat[] = [];

  constructor(
    public api: ApiService,
    public chatService: ChatService,
    private comInterna: ComunicacionInternaService
  ) {
    if ( isDevMode() ) console.log('-- NetworkingChatComponent --');

    this.socket$ = chatService.obtenerMensaje().pipe(
      filter( (chat: Chat) => chat.idOtroUsuario === this.chatService.idOtroUsuario )
    ).subscribe( (chat: Chat) => {
      if ( isDevMode() ) console.log('Recibido nuevo mensaje');

      this.mensajesChat.push(chat);
      chatService.marcarComoLeido(chat.idOtroUsuario);

      setTimeout( () => this.navegarAbajo() );
    });
  }

  ngOnInit(): void {
    this.cargarMensajesChat();
  }

  ngOnDestroy(): void {
    this.socket$.unsubscribe();
    if ( this.mensajes$ ) this.mensajes$.unsubscribe();
  }

  abrirChat(idOtroUsuario: number): void {
    if ( this.chatService.idOtroUsuario == idOtroUsuario ) return;

    this.chatService.idOtroUsuario = idOtroUsuario;
    this.cargarMensajesChat();
  }

  private cargarMensajesChat(): void {
    if ( this.mensajes$ ) this.mensajes$.unsubscribe();
    this.mensajesChat = [];

    if ( !this.chatService.idOtroUsuario ) return;
    if ( !this.api.usuario.chats[this.chatService.idOtroUsuario] ) return;
    this.chatService.marcarComoLeido(this.chatService.idOtroUsuario);

    this.cargandoChat = true;
    this.mensajes$ = this.chatService.listarMensajesChat( this.chatService.idOtroUsuario ).subscribe(
        (mensajes: Chat[]) => {
          this.mensajesChat = mensajes;

          setTimeout( () => {
            this.navegarAbajo();
            this.cargandoChat = false;
          });

          if ( isDevMode() ) console.log('Obteniendo mensajes del chat:\n', mensajes);
        }, _ => {
          this.cargandoChat = false;
          if ( isDevMode() ) console.warn('No se han podido cargar los mensajes del chat');
        }
    );
  }

  enviarMensaje(): void {
    const mensaje = this.textoMensaje.trim();
    if ( !mensaje || !this.chatService.idOtroUsuario ) return;

    this.textoMensaje = '';
    this.chatService.publicarMensaje( this.chatService.idOtroUsuario, mensaje ).subscribe(
        (mensajes: Chat[]) => {
          if ( mensajes[0].idOtroUsuario == this.chatService.idOtroUsuario ) {
            this.mensajesChat = mensajes;
            setTimeout( () => this.navegarAbajo() );
          }

          if ( isDevMode() ) console.log(`Mensaje "${ mensaje }" enviado correctamente`);
        }, _ => {
          if ( isDevMode() ) console.warn('No se ha podido publicar el mensaje')
        }
    );

  }

  filtrarUsuarios(eventTarget: { value: string } | any): void {
    const termino: string = eventTarget && eventTarget.value ? eventTarget.value : '';
    this.chatService.filtrar(termino);
  }

  /**
   * Devuelve true si la fecha corresponde al día de hoy y false si no
   * @param fecha
   */
  mensajeDeHoy(fecha: string): boolean {
    const hoy: string = new Date().toDateString();
    return new Date( fecha ).toDateString() === hoy;
  }

  /**
   * Compara la fecha del mensaje con índice 'i' con la del anterior y dictamina si las fechas corresponden al mismo día
   * @param indice Índice del mensaje
   */
  hayCambioDeFecha(indice: number): boolean {
    if ( indice == 0 ) return true;

    const fechaAnterior = this.mensajesChat[ indice - 1 ].created_at;
    const fecha = this.mensajesChat[indice ].created_at;

    return new Date( fechaAnterior ).toDateString() != new Date( fecha ).toDateString();
  }

  private navegarAbajo(): void {
    try {
      this.myScrollContainer.nativeElement.scrollTop = this.myScrollContainer.nativeElement.scrollHeight
    } catch(err) {
      console.warn('La conversación no ha podido navegar hasta el mensaje más reciente');
    }
  }

  gestionarCalendario(): void {
    this.chatService.cerrar();
    this.comInterna.abrirMiCalendario();
  }
}
