import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './full-pages/login/login.component';
import { BienvenidaComponent } from './full-pages/bienvenida/bienvenida.component';
import { AuthGuard } from './guards/auth.guard';
import { RecuperarPasswordComponent } from "./full-pages/recuperar-password/recuperar-password.component";
import { LoginGuard } from './guards/login.guard';

const appRoutes: Routes = [
  {
    path: 'login',
    component: LoginComponent,
    data: { animationState: 'login', bodyId: 'login' },
    canActivate: [LoginGuard]
  },
  {
    path: 'bienvenida',
    component: BienvenidaComponent,
    data: { animationState: 'bienvenida', bodyId: 'bienvenida' }
  },
  {
    path: 'recuperar-pass',
    component: RecuperarPasswordComponent,
    data: { animationState: 'login', bodyId: 'recuperar-pass' }
  },
  {
    path: 'evento',
    loadChildren: () => import('./pages/pages.module').then( m => m.PagesModule ),
    canActivate: [AuthGuard],
    data: { animationState: 'evento' }
  },
  {
    path: '**',
    pathMatch: 'full',
    redirectTo: 'bienvenida'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes, { scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
