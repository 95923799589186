import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, isDevMode } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Multimedia } from '../models/evento/multimedia/multimedia';
import { Sesion } from '../models/evento/sesion/sesion';

export const UN_SEGUNDO_EN_MS = 1_000;
const headers = new HttpHeaders({ 'Authorization': 'bearer ' + environment.vimeo });

@Injectable({
  providedIn: 'root'
})
export class HelperService {

  constructor(private http: HttpClient) { 

  }

  /**
   * Devuelve true si es un dispositivo móvil y false en el caso contrario
   */
  get mobile(): boolean {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(navigator.userAgent);
  }

  getThumbnail(id ?: string): Observable<string> {
    return this.http.get(`https://api.vimeo.com/videos/${ id }/pictures`, { headers }).pipe(
      map( (response: any) => <string> response.data?.[0]?.sizes?.[2]?.link_with_play_button || response.pictures?.sizes?.[2]?.link_with_play_button || response.pictures?.sizes?.[2]?.link || response.pictures?.base_link )
    );
  }

  /**
   * Carga en la cache las imágenes de fondo de las zonas principales del evento para
   * mejorar la fluidez de la navegación del usuario.
   */
  precargarFondosPrincipales(multimedia: Multimedia[]) {
    [
      'hall', 
      'login', 
      'networking',
      'exposicion_comercial',
      'eposters',
      'auditorio',
      'ponentes',
      'videos',
      'banner_congreso'
    ].forEach( nombre => {

      const fondo: Multimedia | undefined = multimedia.find( img => img.nombre === nombre ); 
      if ( fondo ) {
        const img = new Image();
        img.src = fondo.url;
        if ( isDevMode() ) img.onload = () => console.warn('Imagen precargada:', fondo.url);
      }

    });

  }






  // Información del usuario
  async getUserInfo() {
    let ip : any = 'Unavailable';

    try {
      const address : any = await this.http.get( environment.ipaddress ).toPromise();
      ip = address.ip;
    } catch (err) {
      console.warn('Ip address error');
    }

    return { 
      ip,
      os: this.os,
      browserName: this.browserName,
      browerVersion: this.browserVersion,
      connection: (navigator as any)?.['connection']?.['effectiveType']
    };
  }

  private get browserName() { 
    const agent = window.navigator.userAgent.toLowerCase()
    switch (true) {
      case agent.indexOf('edge') > -1:
        return 'edge';
      case agent.indexOf('opr') > -1 && !!(<any>window).opr:
        return 'opera';
      case agent.indexOf('chrome') > -1 && !!(<any>window).chrome:
        return 'chrome';
      case agent.indexOf('trident') > -1:
        return 'ie';
      case agent.indexOf('firefox') > -1:
        return 'firefox';
      case agent.indexOf('safari') > -1:
        return 'safari';
      default:
        return 'other';
    }
  }
   
  private get browserVersion() {
      var userAgent = navigator.userAgent, tem, 
      matchTest = userAgent.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
      
      if(/trident/i.test(matchTest[1])){
          tem =  /\brv[ :]+(\d+)/g.exec(userAgent) || [];
          return 'IE '+(tem[1] || '');
      }

      if(matchTest[1]=== 'Chrome'){
          tem = userAgent.match(/\b(OPR|Edge)\/(\d+)/);
          if(tem!= null) return tem.slice(1).join(' ').replace('OPR', 'Opera');
      }

      matchTest= matchTest[2]? [matchTest[1], matchTest[2]]: [navigator.appName, navigator.appVersion, '-?'];

      if((tem= userAgent.match(/version\/(\d+)/i))!= null) matchTest.splice(1, 1, tem[1]);
      return matchTest.join(' ');
  }

  private get os() {
    var userAgent = window.navigator.userAgent,
        platform = window.navigator.platform,
        macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
        windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
        iosPlatforms = ['iPhone', 'iPad', 'iPod'],
        os = null;
  
    if (macosPlatforms.indexOf(platform) !== -1) {
      os = 'Mac OS';
    } else if (iosPlatforms.indexOf(platform) !== -1) {
      os = 'iOS';
    } else if (windowsPlatforms.indexOf(platform) !== -1) {
      os = 'Windows';
    } else if (/Android/.test(userAgent)) {
      os = 'Android';
    } else if (!os && /Linux/.test(platform)) {
      os = 'Linux';
    }
  
    return os;
  }

  /**
   * Dada una sesión devuelve true si está activa y false si no.
   * @param sesion Una sesión del evento
   * @returns Devuelve un boolean: true si está activa y false si no.
   */
  estaEnDirecto(sesion: Sesion): boolean {
    return ( 
      sesion.directo === 1 && sesion.inicio_real && !sesion.fin_real
    ) || <boolean> (
      sesion.directo === 0 && new Date(sesion.inicio) < new Date() && new Date() < new Date(sesion.fin)
    );
  }

}
