import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, isDevMode } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ApiService } from './api.service';
import { AuthService } from './auth.service';

@Injectable()
export class Interceptor implements HttpInterceptor {

  constructor(
    private api: ApiService,
    private auth: AuthService
  ) { }

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {

    if ( this.ignorarPeticion(req.urlWithParams) ) return next.handle(req);

    // Obtiene el token del evento
    let token: string = this.api.token;

    // Comprueba si la llamada requiere el api_token
    if ( req.url.includes('bipeek') && this.useApiToken(req.url, req.method) ) {

      if ( this.auth.token ) {
        token = this.auth.token;
      } else {
        this.auth.cerrarSesion();
      }

    }

    let request = req.clone({
      setHeaders: { 'Authorization': `Bearer ${ token }` }
    });

    return next.handle(request).pipe(
      catchError( err => {
        if ( isDevMode() ) console.error(err);

        // Gestiona error unauthenticated
        if ( err.status === 401 && err.error && err.error.message == 'Unauthenticated.' ) {
          this.auth.cerrarSesion();
        }

        return throwError(err);
      })
    );
  }

  private useApiToken(url: string, method: string): boolean {
    const mensaje: boolean = url.includes('/mensaje') && ( url.includes('aprobar') || url.includes('eliminar') || url.includes('mostrar') );
    const foro: boolean = url.includes('/foro');
    const empresas: boolean = url.includes('/empresas');
    const pregunta: boolean = url.includes('/pregunta');
    const chat: boolean = url.includes('/chat');
    const email: boolean = url.includes('/email');
    const asistencia: boolean = url.includes('/asistencia');
    const usuario: boolean = url.includes('/usuario');
    const eventoSesion: boolean = url.includes('/evento/sesion') && method === 'POST';
    const eventoDetalleSesion: boolean = url.includes('/evento/detalle_sesion') && method === 'POST';
    const reuniones: boolean = url.includes('/reuniones');

    return mensaje
        || foro
        || empresas
        || pregunta
        || chat
        || email
        || asistencia
        || usuario
        || eventoSesion
        || eventoDetalleSesion
        || reuniones;
  }

  private ignorarPeticion(urlWithParams: string): boolean {
    const assets: boolean = urlWithParams.includes('assets/');
    const poster: boolean = urlWithParams.includes(this.api.evento?.eposter_url || '...');
    const vimeo: boolean = urlWithParams.includes('vimeo');
    const ipifyorg: boolean = urlWithParams.includes('ipify.org');

    return assets || poster || vimeo || ipifyorg;
  }
}
