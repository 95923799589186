import { Injectable } from '@angular/core';
import { Observable, Subscriber } from 'rxjs';
import { io, Socket } from "socket.io-client";
import { DefaultEventsMap } from 'socket.io-client/build/typed-events';
import { environment } from 'src/environments/environment';
import { Chat } from "../models/chat/chat";
import { Poster } from '../models/eposter/poster';
import { ApiService } from './api.service';
import { HelperService } from './helper.service';

export interface CambioSesion {
  accion: 'sesion_iniciada' | 'sesion_pausada';
  idSala: number;
  idSesion: number;
}

@Injectable({
  providedIn: 'root'
})
export class SocketService {

  readonly directo$: Observable<CambioSesion>;
  readonly conectar$: Observable<void>;

  // El observable conectar$ lo utilizamos para detectar cuando un socket ha perdido la conexión y automáticamente
  // la ha recuperado. Como en los sockets utilizamos las 'rooms', cuando se desconecta y vuelve a conectar,
  // pierde las subscripciones que tenía con cada 'room'. Por este motivo existe este observable.
  
  private socket: Socket<DefaultEventsMap, DefaultEventsMap>;

  constructor(
    private api: ApiService,
    private helper: HelperService
  ) { 
    // TODO: Conectar con wss (websocket) y no con https (polling);
    // Configuración del socket
    this.socket = io( environment.node, {
      transports: ['polling']
    });

    // Eventos de conexión y reconexión al socket
    this.conectar$ = new Observable( (observer: Subscriber<void>) => {
      this.socket.on('connect', () => observer.next() )
    });

    // Eventos de cambio de sesión
    this.directo$ = new Observable( (observer: Subscriber<CambioSesion>) => {
      this.socket.on('sesion_actualizada', (data: CambioSesion) => observer.next(data) );
    });
  }

  async init() {
    this.conectar();
  }

  private emit(event: string, data?: Object) {
    this.socket.emit(event, {
      idEvento: this.api.evento.id,
      token: this.api.token,
      idUsuario: this.api.usuario?.id,
      ...data
    });
  }

  /**
   * Contacta con el socket para recibir todas las actualizaciones en tiempo real del evento.
   * @param idEvento Identificador del evento
   */
  private conectar() {
      this.conectarConEvento();

      this.directo$.subscribe( (cambio: CambioSesion) => {
          this.api.actualizarSesiones(cambio.idSesion, cambio.accion == 'sesion_iniciada');
      });
  }
  
  async conectarConEvento() {
    const blob = await this.helper.getUserInfo();
    const idEvento = this.api.evento.id;
    const token = this.api.token;

    this.socket.emit('unirse_a_evento', idEvento, token, blob);
    this.conectar$.subscribe( () => this.socket.emit('unirse_a_evento', idEvento, token, blob) );
  }

  // MENSAJES CHAT
  enviarMensajeChat(mensaje: Chat) {
    this.socket.emit('nuevo_mensaje_chat', mensaje);
  }

  obtenerMensajeChat(): Observable<Chat> {
    return new Observable( (observer: Subscriber<Chat>) => {
      this.socket.on('nuevo_mensaje_chat', (chat: Chat) => observer.next(chat) )
    });
  }

  // MEETING ONE TO ONE
  crearMeeting(idOtroUsuario: number, idMeeting: number) {
    this.socket.emit('crear_reunion', idOtroUsuario, idMeeting);
  }

  // LOGINS
  login(idUsuario: number, propagar: boolean = false, tipo?: 'login'): void{
    this.socket.emit('login', idUsuario, propagar, tipo);

    this.conectar$.subscribe( () => this.socket.emit('login', idUsuario, false) );
  }

  obtenerLogins(): Observable<number> {
    return new Observable( (observer: Subscriber<number>) => {
      this.socket.on('login', (idUsuario: number) => observer.next(idUsuario));
    });
  }

  // ENCUESTAS
  get encuesta$(): Observable<any> {
    return new Observable( (observer: Subscriber<number>) => {
      this.socket.on('encuesta_lanzada', (sesionId: number) => observer.next(sesionId) )
    });
  }

  lanzarEncuesta(): void {
    this.socket.emit('lanzar_encuesta');
  }

  // MANEJAR SESIONES
  iniciarSesion(idSala: number, idSesion: number): void {
    this.socket.emit('iniciar_sesion', idSala, idSesion);
  }

  finalizarSesion(idSala: number, idSesion: number): void {
    this.socket.emit('pausar_sesion', idSala, idSesion);
  }

  entraSala(idSala: number, idSesion: number | undefined): void {
    this.socket.emit('entra_sala', idSala, idSesion, this.api.evento.id);
  }

  saleSala(): void {
    this.socket.emit('sale_sala', this.api.evento.id);
  }

  // PREGUNTAS SESIONES
  entraEnModera(idSesion: number): void {
    this.socket.emit('entra_en_modera', idSesion);
  }

  saleDeModera(): void {
    this.socket.emit('sale_de_modera');
  }

  enviarPregunta(idMensaje: number): void {
    this.socket.emit('preguntar_en_directo', idMensaje);
  }

  obtenerPreguntas(): Observable<number> {
    return new Observable( (observer: Subscriber<number>) => {
      this.socket.on('preguntar_en_directo', (idMensaje: number) => observer.next(idMensaje) );
    });
  }

  // GESTIÓN STANDS
  abandonarStand(): void {
    this.socket.emit('abandonar_stand');
  }

  obtenerActualizacionesStands(): Observable<number[]> {
    return new Observable( (observer: Subscriber<number[]>) => {
      this.socket.on('actualizacion_stand', (conectados: number[]) => observer.next(conectados) );
    });
  }

  visitarStand(idStand: number): void {
    this.socket.emit('visitar_stand', idStand);
  }

  // E-PÓSTERS
  abandonarPoster() {
    this.socket.emit('abandonar_eposter');
  }

  visitarPoster(poster: Poster) {
    this.socket.emit('visitar_eposter', poster.id, poster.titulo);
  }

  // VISITAS DOCUMENTOS
  consultarRecurso(params: {
    id: number, nombre: string, idEmpresa?: number, tipo: 'multimedia' | 'enlace'
  }) {
    this.socket.emit('consultar_documento', params);
  }

  // BAJO DEMANDA
  onDemand(data: any) {
    this.emit('onDemand:video', data);
  }
}
