import { Multimedia } from '../multimedia/multimedia';

export class Sala {
  audios: any;
  html_extra: any;
  html_extra_alt: any;
  id          !: number;
  nombre      !: string;
  descripcion !: string;
  enlace      ?: string;
  multimedia  !: Multimedia[];
  htmls_extra !: any[];
  orden       !: number;
  traducciones : any;

  constructor(dto: ISalaDTO) {
    this.id = dto.id;
    this.nombre = dto.nombre;
    this.descripcion = dto.descripcion;
    this.enlace = dto.enlace;
    this.multimedia = dto.multimedia;
    this.htmls_extra = dto.htmls_extra;
    this.orden = dto.orden;
    this.traducciones = dto.traducciones;
  }

  // Comentado el 30-nov-2022 por innecesario, se configuran en el servicio de traducción estos atributos
  // get html_extra(): string | undefined {
  //   return this.htmls_extra.find( ({ nombre }) => nombre === 'html_extra' )?.html;
  // }
  
  // get html_extra_alt(): string | undefined {
  //   return this.htmls_extra.find( ({ nombre }) => nombre === 'html_extra_alt' )?.html;
  // }
}

export interface ISalaDTO {
  id: number;
  nombre: string;
  descripcion: string;
  enlace?: string;
  htmls_extra: any[];
  multimedia: Multimedia[];
  orden: number;
  traducciones : any;
}
