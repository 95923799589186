import { AreaTematica } from '../area-tematica/area-tematica';
import { IPonente } from '../ponente/ponente';
import { DetalleSesion } from '../sesion/detalle-sesion';
import { Sesion } from '../sesion/sesion';
import { TipoSesion } from '../sesion/tipo-sesion';

export class Video {

  areas      : AreaTematica[];
  autores    : IPonente[];
  diferido   : boolean;
  fin        : string;
  html       : string;
  idSala     : number;
  idSesion   : number;
  inicio     : string;
  titulo     : string;
  tipoSesion : TipoSesion | null;
  valoracion : number;

  idDetalle ?: number;

  valorar: boolean = false;

  constructor(sesion: Sesion, detalle?: DetalleSesion) {

    this.areas = this.cargarAreasTematicas(sesion?.area_tematica, detalle?.areas_tematicas);
    this.autores = this.cargarAutores(detalle ? [detalle] : sesion.detalles);
    this.diferido = (detalle || sesion).contenido_diferido;
    this.fin = sesion.fin;
    this.html = (detalle || sesion).html_extra;
    this.idDetalle = detalle?.id;
    this.idSala = sesion.sala_evento_id;
    this.idSesion = sesion.id;
    this.inicio = sesion.inicio;
    this.tipoSesion = sesion.tipo_sesion;
    this.titulo = (detalle || sesion).titulo;
    this.valoracion = this.calcularValoracion(detalle || sesion);

  }

  private cargarAutores(detalles: DetalleSesion[]): IPonente[] {
    const autores: IPonente[] = [];

    detalles.forEach( detalle => {
      autores.push(...detalle.autores);
    });

    return autores;
  }

  private cargarAreasTematicas(tematica: AreaTematica | undefined, areasDetalle: AreaTematica[] = []): AreaTematica[] {
    const areasTematicas: AreaTematica[] = areasDetalle;

    if ( tematica ) {
      areasTematicas.unshift(tematica);
    }

    return areasDetalle;
  }

  private calcularValoracion(sesion: DetalleSesion | Sesion): number {
    return sesion.valoraciones === 0 ? 0 : sesion.valoracion / sesion.valoraciones; 
  }

  get estaBajoDemanda(): boolean {
    const contieneVideo: boolean = !!this.html.trim();

    const noEsDiferido: boolean = !this.diferido;
    const esDiferidoPeroYaFinalizado = this.diferido && new Date(this.fin) < new Date();
  
    return contieneVideo && (noEsDiferido || esDiferidoPeroYaFinalizado);
  }
}