import { Injectable, isDevMode } from '@angular/core';
import { Fecha } from 'src/app/models/evento/tipo-registro/fecha';
import { ApiService } from './api.service';
import { Sesion } from '../models/evento/sesion/sesion';
import { Rol } from '../models/usuario/usuario';

@Injectable({
  providedIn: 'root'
})
export class PermisoService {

  constructor( private api: ApiService ) {
    if ( isDevMode() ) console.log('-- PermisoService --');
  }

  get esAdmin(): boolean {
    return this.api.usuario?.rol.includes('administrador');
  }

  get esModerador(): boolean {
    const roles: Rol[] = ['administrador', 'moderador'];
    return roles.some( rol => this.api.usuario?.rol.includes(rol) );
  }

  get puedeAccederPorFecha(): boolean {
    const fechas: Fecha[] | undefined = this.api.usuario.tipo_registro?.fechas;
    const fecha: string = fechas && fechas.length > 0 ? fechas[0].fecha : '3000-01-01';

    const limitacion: boolean = <boolean> (this.api.usuario.tipo_registro?.fechas_limitadas && fechas && fechas.length > 0);

    return !limitacion || new Date() < new Date(fecha);
  }

  get puedeAccederExposicionComercial(): boolean {
    return this.api.usuario.tipo_registro?.exposicion ?? true;
  }

  get puedeAccederNetworking(): boolean {
    const usuarioNetworkingActivado: boolean = <boolean> this.api.usuario?.networking;
    const tipoRegistroPermiteNetworking: boolean = this.api.usuario?.tipo_registro?.networking ?? true;
    
    return usuarioNetworkingActivado && tipoRegistroPermiteNetworking;
  }

  get puedeAccederPosters(): boolean {
    return this.api.usuario.tipo_registro?.posters ?? true;
  }

  puedeVerSesion(idSesion: number | undefined): boolean {
    const noHaySesion: boolean = idSesion === undefined;
    const tieneSesionesLimitadas: boolean = this.api.usuario.tipo_registro?.sesiones_limitadas ?? false;

    const puedeVerLaSesion: boolean = this.api.usuario.tipo_registro?.sesiones?.some(
      ses => ses.sesion_evento_id == idSesion
    ) ?? true;

    return noHaySesion || !tieneSesionesLimitadas || puedeVerLaSesion || this.puedeVerStreaming(idSesion);
  }

  puedeVerStreaming(idSesion: number | undefined): boolean {
    const sesion: Sesion | undefined = this.api.evento.sesiones.find( sesion => sesion.id === idSesion );

    const directo: boolean = sesion?.directo === 1;
    const streaming: boolean = this.api.usuario.tipo_registro?.streaming ?? true;

    return directo && streaming;
  }
}
