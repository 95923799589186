import { Injectable, isDevMode } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class LoginGuard implements CanActivate {

  constructor( private auth: AuthService, private router: Router ) {
    if ( isDevMode() ) console.log('-- LoginGuard --');
  }

  canActivate(
    _1: ActivatedRouteSnapshot,
    _2: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      if ( this.auth.token ) {
          this.router.navigate(['/evento'], { replaceUrl: true });
      }

    return [undefined, null, ''].includes(this.auth.token); 
  }
  
}
