<!-- <section id="login"> -->
<section appLogin *ngIf="!auth.token" id="login">
    <img id="img-fondo-login" class="img-fluid w-100" [src]="fondo" alt="login">
    <div id="login-container" class="container">


      <div *ngIf="api.evento.idiomas.length > 1" class="lang__selector" (click)="selectorIdioma = !selectorIdioma">
        
        <span>
          <img src="assets/imgs/flags/{{ translateService.currentLang }}.png" [alt]="translateService.currentLang">
          {{ 'navbar.idioma.' + translateService.currentLang | translate }}
        </span>
        <svg-icon [class.opened]="selectorIdioma" src="assets/imgs/comun/flecha.svg"></svg-icon>

        <ul *ngIf="selectorIdioma" class="list-group">
          <ng-container *ngFor="let lang of idiomas">
            <li *ngIf ="lang != translateService.currentLang"
            (click)="api.cambiarIdioma(lang)"
            class="list-group-item"
            data-aos="zoom-in">

            <img src="assets/imgs/flags/{{ lang }}.png" [alt]="lang">
            {{ 'navbar.idioma.' + lang | translate }}

          </li>
          </ng-container>
          
        </ul>
      </div>

        <!-- <div *ngIf="idiomas.length > 1" class="row lang__selector">
          <div *ngFor="let lang of idiomas" class="col-6">
            <img src="assets/imgs/flags/{{ lang }}.png" [alt]="lang">
          </div>
        </div> -->

        <app-multimedia
                *ngFor="let nombre of listado"
                [class]="nombre"
                [nombreRecurso]="nombre">
        </app-multimedia>

        <div class="login__form" data-aos="fade-up">
            <form [formGroup]="form" (ngSubmit)="enviar()" action="" class="d-flex flex-column" >
                <div class="d-flex pb-2">
                    <div class="login__icon">
                        <svg-icon src="assets/svgs/login/user.svg"></svg-icon>
                    </div>
                    <input autocomplete="on" class="login__text" type="email" formControlName="email" [placeholder]="'login.usuario' | translate">
                </div>
                <p *ngIf="comprobarValidez('email')" class="login__error">{{ 'login.error.usuario' | translate }}</p>
                <ng-container *ngIf="!recuperarPassword">
                    <div class="d-flex pb-1">
                        <div class="login__icon">
                            <svg-icon src="assets/svgs/login/password.svg"></svg-icon>
                        </div>
                        <input autocomplete="off" class="login__password" type="password" formControlName="password" [placeholder]="'login.password' | translate">
                    </div>
                    <p *ngIf="comprobarValidez('password')" class="login__error">{{ 'login.error.password' | translate }}</p>
                    <p *ngIf="apiError" class="login__error">{{ apiError | translate }}</p>
                </ng-container>

                <div class="networking" *ngIf="!recuperarPassword">

                  <div class="form-check pointer" [title]="'login.networking.text' | translate">
                    <input formControlName="networking" class="form-check-input" type="checkbox" id="flexCheckChecked">
                    <label class="form-check-label" for="flexCheckChecked">
                      {{ 'login.networking.activar' | translate }}
                    </label>
                  </div>

                  <a class="info" role="button" (click)="mostrarAlertaNetworking()">
                    <svg-icon src="assets/imgs/comun/info.svg"></svg-icon>
                  </a>

                </div>

                <a (click)="alternarLoginRecuperacionPassword()" class="pt-1 pb-3 he__olivdado__mi__password" role="button">
                    {{ (recuperarPassword ? 'login.atras' : 'login.he_olvidado_password') | translate }}
                </a>

                <button class="btn login__submit" type="submit" [disabled]="cargando">
                    <span *ngIf="cargando" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    {{ (recuperarPassword ? 'login.recuperar' : 'login.entrar') | translate }}
                </button>
                
            </form>
        </div>
    </div>
</section>

