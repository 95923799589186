import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Enlace } from 'src/app/models/evento/enlace/enlace';
import { Multimedia } from 'src/app/models/evento/multimedia/multimedia';
import { MultimediaService } from 'src/app/services/multimedia.service';
import { Empresa } from '../../models/evento/empresa/empresa';
import { ApiService } from '../../services/api.service';

declare var $: any

@Component({
    selector: 'app-multimedia',
    templateUrl: './multimedia.component.html',
    styleUrls: ['./multimedia.component.scss']
})
export class MultimediaComponent implements OnChanges {

    @Input() empresa       ?: Empresa | undefined;
    @Input() nombreRecurso  : string = '';

    imagen: string | undefined;
    
    constructor(
      private api: ApiService,
      private multimediaService: MultimediaService
    ) {}

    ngOnChanges(changes: SimpleChanges) {
      // Cuando el cambio es de video a video hay un bug que hace que no cambie a pesar de que varía el src.
      // Para ello el atributo this.imagen pasa a undefined, para que el tag <video> no se renderice y luego, en 5 ms,
      // ya se le asigna el valor y se crea otro tag diferente

      // TODO: Mejorar ñapa
      
      this.imagen = undefined;

      setTimeout( () => {
        this.imagen = this.api.obtenerMultimedia( this.nombreRecurso, this.empresa );
      }, 5);
    }

    get video(): string | undefined {
      return this.api.obtenerEnlaceMultimedia( this.nombreRecurso, {
        empresa: this.empresa,
        muted: true
      });
    }

    get enlace(): Enlace | undefined {
        const enlaces: Enlace[] = this.empresa?.enlaces ?? this.api.evento.enlaces;
        return enlaces.find( enlace => enlace.titulo === this.nombreRecurso );
    }

    get multimedia(): Multimedia | undefined {
        const multimedia: Multimedia[] = this.empresa?.multimedia ?? this.api.evento.multimedia;
        return multimedia.find( multi => multi.nombre === this.nombreRecurso );
    }

    registrarVisita() {
      const enlace = this.enlace;
      const multimedia = this.multimedia;

      if ( enlace ) {
        this.multimediaService.visitar(enlace.id, enlace.titulo, 'enlace', this.empresa?.id);
      }

      if ( multimedia ) {
        this.multimediaService.visitar(multimedia.id, multimedia.nombre || multimedia.url, 'multimedia', this.empresa?.id);
      }
    }

}
