// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    // api: 'https://app.bipeek.com/api', // Producción
    api: 'https://dev.bipeek.com/api', // Desarrollo
    // api: 'https://dev2.bipeek.com/api', // Desarrollo al cuadrado

    ipaddress: 'https://api64.ipify.org/?format=json',
    
    node: 'https://premiumnode.onsitevents.com/', // Producción
    // node: 'https://nodeserver.onsitevents.com/', // Pruebas
    // node: 'https://nodesyndeno-dev.onsitevents.com/', // Pruebas syndeno
    // node: 'http://localhost:8080/', // Local
    // node: 'http://192.168.1.71:8080/', // Mac mini de Carles

    production: false,
    vimeo: '55b5426b55ff162b7dc3a20dbd90d868'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
