import { transition, trigger, style, query, group, animate, animateChild } from '@angular/animations';

export const routeTransitionAnimations = trigger('triggerName', [
    transition(
        'login <=> bienvenida, ' +
        'login <=> evento, ' +
        'bienvenida <=> evento',
        [
            style({ position: 'relative' }),
            query(':enter, :leave', [
                style({
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    width: '100%'
                })
            ]),
            query(':enter', [style({  opacity: 0 })]),
            query(':leave', animateChild()),
            group([
                query(':leave', [animate('.5s linear', style({ opacity: 0 }))]),
                query(':enter', [animate('.5s linear', style({ opacity: 1 }))])
            ]),
            query(':enter', animateChild())
        ]
    )
]);
