import { Injectable } from '@angular/core';
import Swal, { SweetAlertOptions, SweetAlertResult } from 'sweetalert2';
import { Alerta } from '../models/common/alerta';

const CINCO_SEGUNDOS_EN_MS = 1000 * 5;

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  private _alertas: Alerta[] = [];

  async showSweetAlert(options: SweetAlertOptions = {}, responses: { [key: string] : string } = {}): Promise<SweetAlertResult<any>> {
    const swal = <any> await Swal.fire(options);
    
    Object.keys( responses ).filter( 
      key => swal[key]
    ).forEach(
      key => eval(responses[key])
    );

    return swal;
  }

  get alertas(): Alerta[] {
    return this._alertas;
  }

  push(alerta: Alerta) {
    this._alertas.push(alerta);
    setTimeout( () => this.alertas.shift(), alerta.milisegundos ?? CINCO_SEGUNDOS_EN_MS );
  }
}
