import { Directive, isDevMode, OnDestroy, OnInit } from '@angular/core';

@Directive({
  selector: '[appLogin]'
})
export class LoginDirective implements OnInit, OnDestroy {

  private style: HTMLStyleElement;

  constructor() {
    if ( isDevMode() ) console.log('-- LoginDirective --');

    this.style = this.createStyle();
  }

  ngOnInit() {
    document.head.appendChild(this.style);
  }

  ngOnDestroy() {
    this.style.remove();
  }

  private createStyle(): HTMLStyleElement {
    const style = document.createElement('style');

    style.id = 'remove-mobile-alert';
    style.innerHTML = `.mensaje-mobile { display: none !important; }`

    return style;
  }
}
