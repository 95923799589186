import { Component, isDevMode } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { finalize } from 'rxjs/operators';
import { Language } from 'src/app/models/api/lang.type';
import { AlertService } from 'src/app/services/alert.service';
import { ApiService } from '../../services/api.service';
import { AuthService } from '../../services/auth.service';
import { FondosService } from '../../services/fondos.service';
import { RegistroService } from '../../services/registro.service';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent {

    readonly fondo: string;
    readonly idiomas: Language[];

    form: FormGroup;
    hayErrores: boolean = false;
    apiError: string = '';

    recuperarPassword: boolean = false;

    cargando: boolean = false;

    selectorIdioma: boolean = false;

    constructor(
        fb: FormBuilder,
        fondosService: FondosService,
        public api: ApiService,
        public auth: AuthService,
        public translateService: TranslateService,
        private alertService: AlertService,
        private registroService: RegistroService,
        private router: Router
    ) {
        if ( isDevMode() ) console.log('-- LoginComponent --');

        this.form = fb.group({
          email: ['', Validators.required],
          password: ['', Validators.required],
          networking: [true, Validators.required]
        });

        this.fondo = fondosService.obtenerFondo('login');
        this.idiomas = api.evento.idiomas;
    }

    get listado(): string[] {
      const recursos: string[] = [
        ...this.api.evento.multimedia.map( multimedia => multimedia.nombre || '' ),
        ...this.api.evento.enlaces.map( enlace => enlace.titulo || '' ),
      ].filter(
        nombre => nombre?.startsWith('login_')
      );

      return [...new Set(recursos)];
    }

    mostrarAlertaNetworking() {
      this.alertService.showSweetAlert({
        icon: 'info',
        title: this.translateService.instant('login.networking.title'),
        text: this.translateService.instant('login.networking.text'),
        confirmButtonText: this.translateService.instant('login.networking.aceptar')
      });
    }

    alternarLoginRecuperacionPassword(): void {
        this.form.reset();
        this.recuperarPassword = !this.recuperarPassword;
    }

    comprobarValidez(formControlName: string): boolean {
        return this.hayErrores && this.form.controls[formControlName].invalid;
    }

    enviar(): void {
        this.apiError = '';

        if ( this.recuperarPassword && this.form.value.email?.trim() ) {
            this.recuperar();
        } else if ( this.form.invalid ) {
            this.hayErrores = true;
        } else {
            this.login();
        }
    }

    private login(): void {
        this.cargando = true;

        this.auth.login( this.form.value ).pipe(
            finalize( () => this.cargando = false )
        ).subscribe(
            _ => {
                if ( isDevMode() ) console.log('Sesion iniciada correctamente');

                const url = localStorage.getItem('url-to-navigate') || '/evento';
                this.router.navigate( [url], { replaceUrl: true } ).then( () => localStorage.removeItem('url-to-navigate') );
            }, ({ error }) => {
                if ( isDevMode() ) console.error(error);

                /**
                 * Comprobar tipo de error.
                 * 1. Si el usuario es correcto pero no ha verificado su registro en el evento.
                 * 2. Error de credenciales
                 */

                if (error?.error?.includes('El registro del usuario en el evento no ha sido verificado') ) {
                    this.apiError = 'login.error.no_verificado';
                    this.registroService.enviarCorreoVerificacion( this.form.value.email ).subscribe();
                } else {
                    this.apiError = error?.error || 'login.error.credenciales';
                }
            }
        );
    }

    private recuperar(): void {
        this.cargando = true;

        this.auth.recuperarPassword( this.form.value.email ).pipe(
            finalize( () => this.cargando = false )
        ).subscribe(
            next => {
                this.alertService.push({
                    tipo: 'info',
                    mensaje: next.ok
                });

                setTimeout( () => location.href = '/login', 10 * 1000 );
            }, err => {
                if ( err.error && err.error.error ) {
                    this.alertService.push({
                        tipo: 'danger',
                        mensaje: err.error.error
                    });
                }
            }
        );
    }

}
