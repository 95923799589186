import { Injectable, isDevMode } from '@angular/core';
import { SocketService } from './socket.service';

@Injectable({
  providedIn: 'root'
})
export class MultimediaService {

  constructor(
    private socket: SocketService
  ) {
    if ( isDevMode() ) console.log('-- MultimediaService --');
  }

  visitar(id: number, nombre: string, tipo: 'multimedia' | 'enlace', idEmpresa?: number | undefined) {
    this.socket.consultarRecurso({ id, nombre, idEmpresa, tipo });
  }
}
