import { Injectable, isDevMode } from '@angular/core';
import { ActivationEnd, Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { filter, map } from 'rxjs/operators';

declare var Fancybox: any;

@Injectable({
  providedIn: 'root'
})
export class FancyboxService {

  private _onDestroy : Subject<any> = new Subject();
  private _onDone    : Subject<any> = new Subject();

  constructor( private router: Router ) {

  }

  init() {
    Fancybox.bind('[data-fancybox]', {

      Hash: false,
      closeButton: 'inside',
      dragToClose: false,
      trapFocus: false,
      
      on: {
        destroy: (fancybox: any) => this._onDestroy.next(fancybox),
        done: (fancybox: any) => this._onDone.next(fancybox),
      }
    });

    this.router.events.pipe(
      filter( event => event instanceof ActivationEnd && event.snapshot.firstChild === null ),
      map( event => (event as ActivationEnd).snapshot.data )
    ).subscribe( () => Fancybox.close() );
    
  }

  closeById(id: string): void {
    const btnClose: HTMLElement | null = document.querySelector(`#${ id } .carousel__button.is-close`);
    btnClose?.click();
  }

  close(): void {
    Fancybox.close();
  }

  get onDestroy$(): Observable<any> {
    return this._onDestroy.asObservable();
  }

  get onDone$(): Observable<any> {
    return this._onDone.asObservable();
  }
}
