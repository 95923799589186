import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { ApiService } from './api.service';
import { CreacionMeetingZoom } from "../models/one-to-one/creacion-meeting-zoom";
import { map } from "rxjs/operators";

const BASE_URL = environment.node + 'zoom/';

@Injectable({
    providedIn: 'root'
})
export class ZoomService {

    constructor(
        private api: ApiService,
        private http: HttpClient
    ) { }

    crearUsuario(): Observable<any> {
        return this.http.post(BASE_URL + 'usuarios', {
            email: this.api.usuario.usuario.email,
            nombre: this.api.usuario.usuario.nombre,
            apellidos: this.api.usuario.usuario.apellidos
        });
    }

    crearReunion(meeting: CreacionMeetingZoom): Observable<{ id: number, url: string }> {
        return this.http.post(BASE_URL + 'meetings', {
            email: this.api.usuario.usuario.email,
            topic: meeting.asunto,
            agenda: meeting.mensaje,
            start_time: this.formatearFechaIsoString(meeting.inicio), // yyyy-MM-ddThh:mm:ssZ
            duration: meeting.duracionEnMinutos
        }).pipe(
          map( (respuesta: any) => {
            return { id: respuesta.id, url: respuesta.join_url }
          } )
        );
    }

    eliminarReunion(idMeeting: number): Observable<any> {
      return this.http.delete(BASE_URL + 'meetings/' + idMeeting);
    }

    private formatearFechaIsoString(fecha: Date): string {
        const pad = (n: number) => n < 10 ? '0' + n : n;

        return fecha.getUTCFullYear() +
            '-' + pad(fecha.getUTCMonth() + 1) +
            '-' + pad(fecha.getUTCDate()) +
            'T' + pad(fecha.getUTCHours()) +
            ':' + pad(fecha.getUTCMinutes()) +
            ':' + pad(fecha.getUTCSeconds()) +
            'Z';
    }
}
