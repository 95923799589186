import { Component, isDevMode } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Language } from 'src/app/models/api/lang.type';
import { Menu } from 'src/app/models/config/menu.interface';
import { ApiService } from 'src/app/services/api.service';
import { MultimediaService } from 'src/app/services/multimedia.service';
import { AuthService } from '../../services/auth.service';
import { ComunicacionInternaService } from '../../services/comunicacion-interna.service';
import { PermisoService } from '../../services/permiso.service';
@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent {

    menu: Menu;
    idiomas: Language[];
    lang: string;

    constructor(
        public permiso: PermisoService,
        public auth: AuthService,
        public api: ApiService,
        private comInterna: ComunicacionInternaService,
        private multimediaService: MultimediaService,
        public translateService: TranslateService
    ) {
        this.lang = translateService.currentLang;
        this.menu = this.obtenerMenu();
        this.idiomas = api.evento.idiomas;
    }

    abrirPopUp(popUp: 'usuario' | 'hospitality' | 'ayuda') {
        this.comInterna.abrirPopUp(popUp);
    }

    private obtenerMenu(): Menu {
        const checkMultiLang = (item: any) => {
            if ( item.titulo[this.lang] ) {
                item.titulo = item.titulo[this.lang];
            }

            // Check if data is an array
            if ( Array.isArray(item.data) ) {
                item.data = item.data.map(checkMultiLang);
            }

            return item;
        };

        let menu: Menu = [];

        try {
            menu = JSON.parse( this.api.evento.frontend_colores ).premium.menu;
            menu = menu.map(checkMultiLang);
        } catch (e) {
            if ( isDevMode() ) console.warn(e);
        }

        return menu;
    }

    visitarEnlace(nombre: string, url: string) {
        this.multimediaService.visitar(0, `enlace-navbar: ${ nombre } (${ url })`, 'enlace');
    }
}
