import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { CreacionMeetingZoom } from "../models/one-to-one/creacion-meeting-zoom";
import { Reunion } from '../models/one-to-one/reunion';
import { ApiService } from './api.service';
import { ChatService } from './chat.service';
import { PermisoService } from './permiso.service';
import { SocketService } from './socket.service';
import { ZoomService } from "./zoom.service";

const BASE_URL = environment.api + '/reuniones';

export enum RespuestaGenerarReunion {
  ERROR = 'error',
  REUNION_CREADA = 'reunion-creada',
  USUARIO_NO_CREADO = 'usuario-no-creado'
}

@Injectable({
  providedIn: 'root'
})
export class OneToOneService {

  constructor(
      private api: ApiService,
      private chat: ChatService,
      private http: HttpClient,
      private translate: TranslateService,
      private permiso: PermisoService,
      private socket: SocketService,
      private zoom: ZoomService
  ) { }

  abrirHospitality() {
    if ( !this.permiso.puedeAccederNetworking ) alert('No puedes acceder a esta sección.');
    else document.getElementById('open-hospitality-pop-up')?.click();
  }

  gestionarReunion(
      idReunion: number | string,
      idInterlocutor: number,
      accion: 'aceptar' | 'rechazar',
      motivo?: string
  ): Observable<Reunion> {
    const formData = new FormData();

    if ( accion == 'rechazar' && motivo )
      formData.append('motivo', motivo);

    return this.http.post<{ reunion: Reunion }>(BASE_URL + '/' + idReunion + '/' + accion, formData).pipe(
        map( (respuesta: { reunion: Reunion }) => {
          const reunion = respuesta.reunion;
          const indice = this.api.usuario.reuniones_invitado.findIndex( r => r.id == reunion.id );

          if ( indice > -1 ) this.api.usuario.reuniones_invitado[indice] = reunion;
          this.chat.publicarMensaje(idInterlocutor, this.translate.instant(`one_to_one.acciones.mensaje.` + accion)).subscribe();

          return respuesta.reunion;
        })
    );
  }

  async generarReunion(idUsuarioInvitado: number, meeting: CreacionMeetingZoom): Promise<RespuestaGenerarReunion> {
    let meetingCreada: { id: number, url: string } | undefined;

    try {
      meetingCreada = await this.zoom.crearReunion( meeting ).toPromise();
    } catch (err: any) {

      if ( err?.status == 404 && err?.error?.code == 1001 ) {
        this.zoom.crearUsuario().subscribe();
        return RespuestaGenerarReunion.USUARIO_NO_CREADO;
      } else {
        return RespuestaGenerarReunion.ERROR;
      }

    }

    try {
      await this.guardarReunion( idUsuarioInvitado, meeting, meetingCreada.url).toPromise();
      this.chat.publicarMensaje(idUsuarioInvitado, this.construirMensaje(meeting)).subscribe();

      return RespuestaGenerarReunion.REUNION_CREADA;
    } catch (err) {
      this.zoom.eliminarReunion( meetingCreada.id ).subscribe();
      return RespuestaGenerarReunion.ERROR;
    }
  }

  obtenerReuniones(): Observable<Reunion[]> {
    return this.http.get<{ reuniones: Reunion[]}>(BASE_URL).pipe(
        map( (respuesta: { reuniones: Reunion[] }) => {
          this.api.usuario.reuniones_organizador = respuesta.reuniones.filter(
              reunion => reunion.organizador?.id == this.api.usuario.id
          );

          this.api.usuario.reuniones_invitado = respuesta.reuniones.filter(
              reunion => reunion.invitado?.id == this.api.usuario.id
          );

          return respuesta.reuniones;
        })
    );
  }

  private guardarReunion(idUsuarioInvitado: number, meeting: CreacionMeetingZoom, url: string): Observable<Reunion> {

    const duracionEnHoras = meeting.duracionEnMinutos / 60;

    return this.http.post(BASE_URL + '/nueva', {
      usuario_invitado_id: idUsuarioInvitado,
      asunto: meeting.asunto,
      inicio: this.formatearFechaLocal(meeting.inicio),
      fin: this.formatearFechaLocal(meeting.inicio, duracionEnHoras),
      url_zoom: url
    }).pipe(
        map( (respuesta: any) => respuesta.reunion ),
        tap( (reunion: Reunion) => this.api.usuario.reuniones_organizador.push(reunion) ),
        tap( (reunion: Reunion) => this.socket.crearMeeting(idUsuarioInvitado, reunion.id) )
    );

  }

  private formatearFechaLocal(fecha: Date, sumaHoras: number = 0): string {
    const pad = (n: number) => n < 10 ? '0' + n : n;

    return fecha.getFullYear() +
      '-' + pad(fecha.getMonth() + 1) +
      '-' + pad(fecha.getDate()) +
      ' ' + pad(fecha.getHours() + sumaHoras) +
      ':' + pad(fecha.getMinutes()) +
      ':' + pad(fecha.getSeconds());
  }

  private construirMensaje(meeting: CreacionMeetingZoom): string {
    return '<h1 class="text-bold">¡Hola, me gustaría tener una reunión One To One contigo!</h1>' +
    `<p>Asunto: ${ meeting.asunto }.<br>Mensaje: ${ meeting.mensaje }.</p>`;

    // <div class="contenedor-botones" id="${idReunion}">
    //     <button class="btn-aceptar">Aceptar</button>
    //     <button class="btn-rechazar">Rechazar</button>
    // </div>
  }
}
