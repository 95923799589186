<section id="banner-title">

  <div class="container">
    <div class="title d-flex align-items-center flex-wrap">
      <h1>{{ 'emergente.congreso_virtual' | translate }} | <span>{{ api.evento | fechas }}</span> </h1>
    </div>
    <h2 class="banner">{{ banner | translate }}</h2>

    <ng-content></ng-content>
  </div>
  
</section>