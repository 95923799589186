import { AfterViewInit, Directive, ElementRef, HostBinding, isDevMode } from '@angular/core';

@Directive({
  selector: '[drag-and-drop-chat]'
})
export class DragDropChatDirective implements AfterViewInit {

  private top  ?: number;
  private left ?: number;
  private dragDropChat: boolean = false;

  @HostBinding('attr.style') style: any;

  constructor(private elementRef: ElementRef) {
    if ( isDevMode() ) console.log('-- DragDropChatDirective --');
    
    this.elementRef.nativeElement.style.left = this.left + '%';
    this.elementRef.nativeElement.style.top = this.top + '%';
  }

  ngAfterViewInit(): void {
    document.getElementById( 'mover-chat' )?.addEventListener('mousedown', () => this.dragDropChat = true );

    document.addEventListener('mouseup', () => this.dragDropChat = false );
    document.addEventListener('mousemove', (ev: MouseEvent) => {
      const xCord = ev.clientX;
      const yCord = ev.clientY;

      const xPercent = xCord / window.innerWidth * 100;
      const yPercent = yCord / window.innerHeight * 100;

      if ( this.dragDropChat ) {
        if ( 1 < xPercent && xPercent < 100 ) this.left = xPercent - 1;
        if ( 1 < yPercent && yPercent < 100 ) this.top = yPercent - 1;
      }

      this.elementRef.nativeElement.style.left = this.left + '%';
      this.elementRef.nativeElement.style.top = this.top + '%';
    });
  }
}
