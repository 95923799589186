import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'values'
})
export class ValuesPipe implements PipeTransform {

  transform(object: { [key: string]: any }): any {
    return Object.values(object);
  }

}
