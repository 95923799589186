import { Component, EventEmitter, isDevMode, Output } from '@angular/core';

@Component({
  selector: 'app-cerrar-emergente',
  templateUrl: './cerrar-emergente.component.html',
  styleUrls: ['./cerrar-emergente.component.scss']
})
export class CerrarEmergenteComponent {

  @Output() cerrar = new EventEmitter<void>();

  constructor() {
    if ( isDevMode() ) console.log('-- --');
  }

}
