export interface IPonenteConfig {
  titulo: boolean;
  cargo: boolean;
  centro_trabajo: boolean;
}

export interface IPonente {
  apellidos: string;
  avatar: string;
  cargo: string
  centro_trabajo: string;
  curriculum: string;
  email: string;
  id: number;
  nombre: string;
  titulo: string;
  usuario_evento_id: number;
}


export class Ponente {

  constructor(private iPonente: IPonente, private config?: IPonenteConfig) {  }

  get avatar(): string {
    return this.iPonente.avatar;
  }

  get titulo(): string {
    return this.config?.titulo || true ? this.iPonente.titulo : '';
  }

  get centro_trabajo(): string {
    return this.config?.centro_trabajo || true ? this.iPonente.centro_trabajo : '';
  }

  get cargo(): string {
    // Para evitar información duplicada en el título y el cargo
    const cargo = this.config?.cargo || true ? this.iPonente.cargo : '';
    return cargo === this.titulo ? '' : cargo;
  }

  get curriculum(): string {
    return this.iPonente.curriculum;
  }

  get apellidos(): string {
    return this.iPonente.apellidos;
  }

  get nombre(): string {
    return this.iPonente.nombre;
  }

  get email(): string {
    return this.iPonente.email;
  }

  get className(): string {
    const nombre = this.toString().toLowerCase().replace(/ /g, '-');

    // Remove accents
    return nombre.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  }

  sonIdenticos(ponente: Ponente): boolean {
    return this.nombre === ponente.nombre && this.apellidos === ponente.apellidos && this.email === ponente.email;
  }

  public toString(): string {
    return this.iPonente.nombre + ' ' + this.iPonente.apellidos;
  }
}
