import { Pipe, PipeTransform } from '@angular/core';
import { Evento } from '../../models/evento/evento';
import { Sala } from '../../models/evento/sala/sala';
import { ApiService } from '../../services/api.service';

@Pipe({
  name: 'sala'
})
export class SalaPipe implements PipeTransform {

  constructor(
    private api: ApiService
  ) { }

  transform(id: number | string | null): string | number | null {
    const evento: Evento = this.api.evento;
    const sala: Sala | undefined = evento.salas.find( s => s.id == id );

    return sala?.nombre || id || null;
  }

}
