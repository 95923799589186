import { Component, isDevMode } from '@angular/core';
import { FondosService } from 'src/app/services/fondos.service';

@Component({
    selector: 'app-bienvenida',
    templateUrl: './bienvenida.component.html',
    styleUrls: ['./bienvenida.component.scss']
})
export class BienvenidaComponent {

  readonly fondo: string;

  constructor( fondosService: FondosService ) {
    if ( isDevMode() ) console.log('-- BienvenidaComponent --');

    this.fondo = fondosService.obtenerFondo('bienvenida');
  }
}
