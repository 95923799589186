import { Injectable, isDevMode } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivationEnd, Router } from '@angular/router';
import { filter, map } from 'rxjs/operators';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class DomService {

  constructor(
    private api: ApiService,
    private router: Router,
    private title: Title
  ) { }

  init() {
    document.body.classList.remove('loading');
    
    // Favicon
    const favicon: string | undefined = this.api.evento.favicon;
    if ( favicon ) {
      localStorage.setItem('favicon', favicon);
      (<HTMLLinkElement> document.querySelector('#appIcon')).href = favicon;
    }

    // Título de la página
    const title = this.api.evento.nombre;
    this.title.setTitle(title);
    localStorage.setItem('title', title);

    // Configuración de estilos
    this.insertarEstilos();

    this.router.events.pipe(
      filter( event => event instanceof ActivationEnd && event.snapshot.firstChild === null ),
      map( event => (event as ActivationEnd).snapshot.data )
    ).subscribe( ({ bodyId }) => {
      document.body.id = bodyId;
    });
  }

  private insertarEstilos(): void {
    try {
      const css = JSON.parse( this.api.evento.frontend_colores ).premiumCSS;
      const head = document.head || document.getElementsByTagName('head')[0];
      const style = document.createElement('style');

      head.append( style );
      style.appendChild(document.createTextNode(css));
    } catch (e) {
      if ( isDevMode() ) console.warn(e);
    }
  }
}
