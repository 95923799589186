import { Pipe, PipeTransform } from '@angular/core';
import { IPonente, IPonenteConfig, Ponente } from 'src/app/models/evento/ponente/ponente';
import { ConfigService } from 'src/app/services/config.service';

@Pipe({
  name: 'ponente'
})
export class PonentePipe implements PipeTransform {
  private config: IPonenteConfig;

  constructor(readonly configService: ConfigService) {
    this.config = configService.ponentes;
  }

  transform(ponente: IPonente): Ponente {
    return new Ponente(ponente, this.config);
  }

}
