import { HttpClient } from "@angular/common/http";
import { Injectable } from '@angular/core';
import { BroadcastChannel } from 'broadcast-channel';
import { Observable } from "rxjs";
import { map, tap } from 'rxjs/operators';
import { ApiUsuario } from "src/app/models/api/api-usuario";
import { Usuario } from "src/app/models/usuario/usuario";
import { environment } from "../../environments/environment";
import { ApiService } from './api.service';
import { SocketService } from "./socket.service";
import { UsuarioService } from './usuario.service';

const URL = environment.api;

interface Global {
  token: string | undefined | null;
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private readonly global: Global;
  private readonly broadcastChannel: BroadcastChannel;

  constructor(
    private api: ApiService,
    private http: HttpClient,
    private socket: SocketService,
    private usuarioService: UsuarioService
  ) {
    this.global = this.inicializarGlobal();
    this.broadcastChannel = this.inicializarBroadcastChannel();

  }

  private inicializarGlobal(): Global {
    return {
      token: localStorage.getItem('api_token')
    };
  }

  // Getters / Setters

  get token(): string | undefined | null {
    return this.global.token;
  }

  set token(token: string | undefined | null) {
    if ( token ) localStorage.setItem('api_token', token);
    else localStorage.removeItem('api_token');

    this.global.token = token;
  }

  // Comunicación entre pestañas del mismo navegador
  inicializarBroadcastChannel(): BroadcastChannel {
    const bc = new BroadcastChannel('auth');
    bc.onmessage = (_: MessageEvent) => location.href = '/login';

    return bc;
  }

  // Login

  login(
    credenciales: { email: string, password: string, networking: boolean }
  ): Observable<Usuario> {
      return this.http.post<ApiUsuario>(URL + '/login', credenciales).pipe(
          map( (response: ApiUsuario) => response.usuario_evento ),
          tap( (usuario: Usuario) => {
            this.api.usuario = usuario;
            this.token = usuario.api_token;

            this.broadcastChannel.postMessage('login');
          }),
          tap( (usuario: Usuario) => {
            this.socket.login(usuario.id, !usuario.login_multiple, 'login');
            if ( usuario.networking !== credenciales.networking ) {
              this.usuarioService.cambiarEstadoNetworking( credenciales.networking ).subscribe();
            } 
          })
      );
  }
  
  // Cerrar sesión

  cerrarSesion() {
    this.broadcastChannel.postMessage('logout');

    localStorage.removeItem('api_token');
    location.href = '/login';
  }

  // Comprobar validez del token

  comprobarValidezToken(): Observable<Usuario> {
    return this.http.get<ApiUsuario>( URL + '/usuario' ).pipe(
      map( (respuesta: ApiUsuario) => respuesta.usuario_evento ),
      tap( (usuario: Usuario) => this.api.usuario = usuario ),
      tap( (usuario: Usuario) => this.socket.login(usuario.id) )
    );
  }


  // Recuperación de contraseñas

  recuperarPassword(email: string): Observable<any> {
    return this.http.post(URL + '/recuperar-password', {
      email: email,
      url_reset: `${ location.protocol }//${ location.host }/recuperar-pass`
    });
  }

  establecerPassword(password: string, tokenRecuperacion: string): Observable<any> {
    return this.http.post(URL + '/recuperar-password/reset-password', {
      password: password,
      token: tokenRecuperacion
    });
  }

  validarTokenRecuperacion(tokenRecuperacion: string | undefined): Observable<any> {
    return this.http.post(
      URL + '/recuperar-password/validar-token', 
      { token: tokenRecuperacion }
    );
  }
}
