<section *ngIf="token" class="login">
    <img class="img-fluid w-100" [src]="fondo" alt="vestibulo">
    <div class="container">

        <div class="login__links">
            <app-multimedia
                    *ngFor="let nombre of ['login_1', 'login_2']"
                    [class]="nombre"
                    [nombreRecurso]="nombre">
            </app-multimedia>
        </div>

        <div (keydown.enter)="enviar(token)" class="login__form" data-aos="fade-up">
            <div class="d-flex flex-column">
                <div class="d-flex pb-2 login__1">
                    <div class="login__icon">
                      <svg-icon src="assets/svgs/login/password.svg"></svg-icon>
                    </div>
                    <input autocomplete="on" class="login__text" type="password" [(ngModel)]="nuevaPass" [placeholder]="'login.password' | translate">
                </div>
                <div class="d-flex pb-1">
                    <div class="login__icon">
                      <svg-icon src="assets/svgs/login/password.svg"></svg-icon>
                    </div>
                    <input autocomplete="off" class="login__password" type="password" [(ngModel)]="confirmarPass" [placeholder]="'login.nueva_password.confirmar' | translate">
                </div>
                <ng-container *ngIf="mostrarErrores">
                    <p *ngIf="!nuevaPass || nuevaPass.length < 6" class="login__error">{{ 'login.error.minimo' | translate }}</p>
                    <p *ngIf="nuevaPass && nuevaPass != confirmarPass" class="login__error">
                        {{ 'login.error.password_diferentes' | translate }}
                    </p>
                </ng-container>

                <button (click)="enviar(token)" class="btn login__submit" type="submit" [disabled]="cargando">
                  <span *ngIf="cargando" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                  {{ 'login.nueva_password.establecer' | translate }}
                </button>
            </div>
        </div>
    </div>
</section>

