import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Pipe({
  name: 'iframe'
})
export class IframePipe implements PipeTransform {

  constructor( private sanitizer: DomSanitizer ) { }

  transform(value: string, type : 'data-src' | 'iframe'): SafeResourceUrl {
    const url = type === 'iframe' ? this.iframe(value) : this.dataSrc(value)
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  private iframe(value: string): string {
    
    const base = value.replace('//vimeo.com', '//player.vimeo.com/video'); 
    const simbolo: '?' | '&' = value.includes('?') ? '&' : '?';
    const fin = 'autoplay=1&autopause=0&background=1&quality=240p';

    return base + simbolo + fin;
  }

  private dataSrc(value: string): string {
    const url: string = !value.includes('<iframe') ? value : value.split('src="')[1].split('"')[0];
    return url.replace( '//player.vimeo.com/video', '//vimeo.com').replace('?h', '/');
  }

}
