import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent {

  @Input() mostrar: boolean = false;
  @Output() mostrarChange = new EventEmitter<boolean>();

  cerrar(): void {
    this.mostrarChange.emit(false);
  }

}
