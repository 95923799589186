import { Encuesta } from '../encuesta/encuesta';
import { Moderador } from './moderador/moderador';
import { Imagen } from './imagen';
import { TipoSesion } from './tipo-sesion';
import { DetalleSesion, IDetalleSesionDTO } from './detalle-sesion';
import { AreaTematica } from '../area-tematica/area-tematica';
import { Multimedia } from '../multimedia/multimedia';
import { IPonente } from '../ponente/ponente';

export class Sesion {
  audios: any;
  id !: number;
  contenido_diferido !: boolean;
  sala_evento_id !: number;
  inicio !: string;
  directo !: 0 | 1;
  fin !: string;
  inicio_real !: string;
  fin_real !: string;
  plazas !: number;
  creditos !: number;
  asistencia_minima !: string;
  titulo !: string;
  htmls_extra !: any[];
  enlace !: string;
  horario_indefinido !: number;
  moderadores !: IPonente[];
  multimedia !: Multimedia[];
  imagen_perfil_moderador !: { [id: number]: Imagen };
  tipo_sesion_evento_id !: number;
  streaming !: string;
  tipo_sesion !: TipoSesion | null;
  detalles !: DetalleSesion[];
  valoracion !: number;
  valoraciones !: number;
  encuesta?: Encuesta;
  area_tematica?: AreaTematica;
  area_tematica_evento_id?: number;
  miniatura?: string;
  
  constructor(dto: ISesionDTO) {
    Object.assign(this, dto);

    this.detalles = dto.detalles.map( detalle => new DetalleSesion(detalle) );
  }

  get html_extra(): string {
    // TODO: Duplicated
    return this.htmls_extra.find( ({ nombre }) => nombre === 'html_extra' )?.html || '';
  }

  set html_extra(html: string) {}

  get html_extra_alt(): string {
    // TODO: Duplicated
    return this.htmls_extra.find( ({ nombre }) => nombre === 'html_extra_alt' )?.html || '';
  }

  set html_extra_alt(html: string) {}
}

export interface ISesionDTO {
  audios: any;
  id: number;
  encuesta?: Encuesta;
  area_tematica?: AreaTematica;
  area_tematica_evento_id?: number;
  contenido_diferido: boolean;
  sala_evento_id: number;
  inicio: string;
  directo: 0 | 1;
  fin: string;
  inicio_real: string;
  fin_real: string;
  plazas: number;
  creditos: number;
  asistencia_minima: string;
  titulo: string;
  miniatura?: string;
  htmls_extra: any[];
  enlace: string;
  horario_indefinido: number;
  moderadores: Moderador[];
  multimedia: Multimedia[];
  imagen_perfil_moderador: { [id: number]: Imagen };
  tipo_sesion_evento_id: number;
  streaming: string;
  tipo_sesion: TipoSesion | null;
  detalles: IDetalleSesionDTO[];
  valoracion: number;
  valoraciones: number;
}
