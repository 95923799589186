import { Component, isDevMode } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { finalize } from 'rxjs/operators';
import { AlertService } from 'src/app/services/alert.service';
import { ApiService } from 'src/app/services/api.service';
import { ChatService } from 'src/app/services/chat.service';

@Component({
  selector: 'app-broadcast',
  templateUrl: './broadcast.component.html',
  styleUrls: ['./broadcast.component.scss']
})
export class DifusionComponent {

  enviando : boolean = false;
  texto    : string = '';

  constructor(
    public api: ApiService,
    private alertService: AlertService,
    private chatService: ChatService,
    private translateService: TranslateService
  ) {
    if ( isDevMode() ) console.log('-- DifusionComponent --');
  }

  async publicar() {
    const swal = await this.alertService.showSweetAlert({
      icon: 'question',
      title: this.translateService.instant('broadcast.titulo'),
      text: this.translateService.instant('broadcast.esta_seguro'),
      showCancelButton: true,
      cancelButtonText: this.translateService.instant('broadcast.cancelar'),
      confirmButtonText: this.translateService.instant('broadcast.confirmar'),
    });

    if ( swal.isConfirmed ) {
      this.enviar();
    }
  }

  private enviar(): void {
    this.enviando = true;

    this.chatService.difundir( this.texto ).pipe(
      finalize( () => this.enviando = false )
    ).subscribe(
      () => {
        this.texto = '';

        this.alertService.showSweetAlert({
          icon: 'success',
          title: this.translateService.instant('broadcast.titulo'),
          text: this.translateService.instant('broadcast.success')
        });

      },
      ({ error }) => {
        
        this.alertService.showSweetAlert({
          icon: 'error',
          title: this.translateService.instant('broadcast.titulo'),
          text: this.translateService.instant('broadcast.error')
        }); 

      }
    );
  }
}
