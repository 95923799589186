import { Pipe, PipeTransform } from '@angular/core';
import { ApiService } from '../../services/api.service';
import { Empresa } from '../../models/evento/empresa/empresa';

@Pipe({
  name: 'empresa'
})
export class EmpresaPipe implements PipeTransform {

  constructor(
      private api: ApiService
  ) { }

  transform(idEmpresa?: number | string): unknown {
    const evento = this.api.evento;
    const empresa: Empresa | undefined = evento.empresas.find( empresa => empresa.id == idEmpresa );

    return empresa?.nombre || evento.nombre;
  }

}
